import { connect } from "react-redux";
// import { useNavigate } from "react-router-dom";
import QuickMenu from "src/components/Shared/QuickMenu";

const GroupEntry = ({ group, setDeleteId, site, ...props }) => {
  // const navigate = useNavigate();

  return (
    <>
      
      <tr key={group?._id} className="flex flex-wrap sm:table-row relative">
        <td className="block w-8/12 sm:w-auto sm:table-cell w-full sm:w-auto whitespace-pre-wrap break-all py-4 px-3 sm:py-3 text-sm text-gray-500">
          <div className="text-gray-900 font-medium">{group?.name}</div>
        </td>
        <td className="absolute top-0 right-0 block sm:table-cell sm:relative whitespace-nowrap py-3 pl-3 pr-4 text-sm font-medium">
          <div className="flex justify-end">
            <QuickMenu
                items={[
                  {
                    name: "Edit",
                    onClick: () => {
                      // navigate(`/group/edit/${group._id}`);
                      props.setEditId(group._id);
                      props.setGroupModalOpen(true);
                      props.setEditGroupStatus(true);
                      props.setAddGroupStatus(false);
                    },
                  },
                  {
                    name: "Delete",
                    onClick: () => {
                      setDeleteId(group._id);
                    },
                    color: "text-red-500",
                  },
                ]}
              />
          </div>
        </td>
      </tr>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
  };
};

export default connect(mapStateToProps, {  })(GroupEntry);