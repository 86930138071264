import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import { Fragment } from "react";
import { classNames } from "src/helpers/classNames";

const SimpleDropdown = ({ options, label = "", setSelected, selected = { id: -1, name: " " }, required = false }) => {
  let selectedVal = selected.name === "None selected" && required ? "" : "";

  return (
    <Listbox value={selected} onChange={setSelected}>
      {({ open }) => (
        <div className="min-w-[80px] w-full flex flex-col">
          {label && <Listbox.Label className="mb-1 block text-sm font-medium text-gray-700">{label}</Listbox.Label>}
          <div className="relative">
            <Listbox.Button
              className={
                "relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:border-highlightColor sm:text-sm " + selectedVal
              }
            >
              <span className="block truncate min-h-[17.5px]">{selected.name}</span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition show={open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
              <Listbox.Options className="absolute z-30 mt-1 w-full bg-white shadow-lg max-h-60 overflow-y-scroll rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {options.map((option, i) => (
                  <Listbox.Option
                    key={i}
                    className={({ active }) => classNames(active ? "text-white bg-highlightColor" : "text-gray-900", "text-sm cursor-default select-none relative py-2 pl-4 pr-4")}
                    value={option}
                  >
                    {({ selected, active }) => (
                      <>
                        <span className={classNames(selected ? "font-semibold" : "font-normal", "pl-3 block truncate")}>{option.name}</span>

                        {selected ? (
                          <span className={classNames(active ? "text-white" : "text-highlightColor", "absolute inset-y-0 left-0 flex items-center pl-1.5")}>
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </div>
      )}
    </Listbox>
  );
};

export default SimpleDropdown;
