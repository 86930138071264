import moment from "moment";
import QuickMenu from "src/components/Shared/QuickMenu";
function ActivityEntry({ activity, setDeleteId, site, ...props }) {
  const formatMemberDate = (date) => {
    if (!date) return "";

    return moment(new Date(date)).format("LLL");
  };

  return (
    <>
      <tr key={activity?._id} className="relative">
        <td className="table-cell w-1/2 sm:w-1/6 whitespace-pre-wrap break-all py-4 px-3 sm:py-3 text-sm text-gray-500">
          <div className="text-gray-900 font-medium">{activity?.username}</div>
          <div className="sm:hidden text-gray-600">{activity?.email}</div>
        </td>
        <td className="sm:table-cell hidden whitespace-pre-wrap break-all py-0.5 px-3 sm:py-3 text-xs sm:text-sm text-gray-500">
          <div className="text-gray-900">{activity?.email}</div>
        </td>
        <td className="md:table-cell hidden whitespace-pre-wrap break-all py-0.5 px-3 sm:py-3 text-xs sm:text-sm text-gray-500">
          <div className="text-gray-900">{activity?.type}</div>
        </td>
        <td className="sm:table-cell w-1/2 sm:w-1/5 whitespace-pre-wrap break-all py-0.5 px-3 sm:py-3 text-xs sm:text-sm text-gray-500">
          <div className="text-gray-900">{activity?.dashboard}</div>
        </td>
        <td className="sm:table-cell hidden whitespace-pre-wrap break-all pt-0.5 pb-4 px-3 sm:py-3 text-xs sm:text-sm text-gray-500">
          <div className="text-gray-900">{formatMemberDate(activity?.created_at)}</div>
        </td>
        <td className="table-cell whitespace-nowrap py-3 pl-3 pr-4 text-xs sm:text-sm font-medium">
          <div className="flex justify-end">
            <QuickMenu
              items={[
                {
                  name: "Delete",
                  onClick: () => {
                    setDeleteId(activity._id);
                  },
                  color: "text-red-500",
                },
              ]}
            />
          </div>
        </td>
      </tr>
    </>
  );
}

export default ActivityEntry;
