import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getMenuData } from "src/actions/menu";
import AuthLayout from "src/layouts/AuthLayout";
import DefaultLayout from "src/layouts/DefaultLayout";

const Layout = (props) => {
  const [routeLayout, setRouteLayout] = useState("auth");

  const routeTypes = {
    auth: { routes: ["/", "/forgot-password", "/verify-token", "/reset-password", "/login", "/sso-logout"], layout: <AuthLayout {...props}>{props.children}</AuthLayout> },
    error: { routes: ["/404", "/500"], layout: <>{props.children}</> },
  };

  const isAuthRouteLayout = (url) => {
    for (let type in routeTypes) {
      if (routeTypes[type].routes.includes(url)) {
        setRouteLayout(routeTypes[type].layout);
        return;
      }
    }
    setRouteLayout(<DefaultLayout {...props}>{props.children}</DefaultLayout>);
  };

  useEffect(() => {
    isAuthRouteLayout(window.location.pathname);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  return <>{routeLayout}</>;
};

const mapStateToProps = (state) => {
  return {
    collapse: state.collapse,
    menus: Object.values(state.menus),
    site: state.site,
  };
};

export default connect(mapStateToProps, { getMenuData })(Layout);
