import Preloader from "src/components/Shared/Preloader";
import NoData from "src/components/Shared/NoData";
import Table from "src/components/Shared/Table/Table";
import TableBody from "src/components/Shared/Table/TableBody";
import TableHeader from "src/components/Shared/Table/TableHeader";

const PortalUserList = (props) => {
  return (
    <div className="w-full flex flex-col">
      <Table>
        <TableHeader>
          <tr className="bg-gray-50">
            <th scope="col" className="rounded-r-xl relative py-3.5 pl-3 pr-4 sm:pr-6">
              <span className="sr-only">Edit</span>
            </th>
            <th scope="col" className="rounded-l-xl py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
              Name
            </th>
            <th scope="col" className="hidden lg:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Email
            </th>
            <th scope="col" className="hidden sm:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Permissions
            </th>
          </tr>
        </TableHeader>
        <TableBody>
          {props.loaded && props.children}
          {props.loaded && props.usersExist ? (
            <tr>
              <td colSpan="5">
                <NoData />
              </td>
            </tr>
          ) : !props.loaded ? (
            <tr>
              <td colSpan="5">
                <Preloader />
              </td>
            </tr>
          ) : null}
        </TableBody>
      </Table>
    </div>
  );
};

export default PortalUserList;
