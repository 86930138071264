import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { getDashboardData } from "src/actions/dashboard";
import { getOperatorData } from "src/actions/operator";
import { manageSiteSettings } from "src/actions/site";
import Button from "src/components/Shared/Button";
import SimpleDropdown from "src/components/Shared/Forms/SimpleDropdown";
import AddPermissionForm from "src/components/Users/Add/AddPermissionForm";
import { operatorNameConverter } from "src/helpers/operatorNameConverter";
import Input from "../Shared/Forms/Input";
import MultiSelect from "../Shared/Forms/MultiSelect";

const SSOServiceAttribute = ({ site, manageSiteSettings, dashboards, operators, ...props }) => {
  const [sso_role, setRole] = useState("");
  const [sso_value, setRoleValue] = useState({ _id: -1, name: "None selected" });
  const [sso_trusted_attribute, setSsoTrustedAttribute] = useState("");
  const [sso_trusted_attribute_alt, setSsoTrustedAttributeAlt] = useState({ _id: -1, name: "None selected" });
  const [sso_trusted_attribute_value, setSsoTrustedAttributeValue] = useState("");
  const [trusted_attribute, setTrustedAttribute] = useState("");
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [permissionFormCount, setPermissionFormCount] = useState([0]);
  const [permissionState, setPermissionState] = useState({});
  const prevPermissionState = usePrevious(permissionState);

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  }

  // Load dashboards
  useEffect(() => {
    const ac = new AbortController();

    const loadDashboards = async () => {
      try {
        props.getDashboardData({}, ac.signal);
      } catch (err) {
        console.dir(err.message);
      }
    };

    loadDashboards();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Load operators
  useEffect(() => {
    const ac = new AbortController();

    const loadOperators = async () => {
      try {
        props.getOperatorData({}, ac.signal);
      } catch (err) {
        // console.dir(err.message);
      }
    };

    loadOperators();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (site?.permissions && site?.permissions.length) {
      const updatedStateCount = [];
      const finalPermissions = {};
      Object.keys(site.permissions).forEach((count) => {
        finalPermissions[count] = site.permissions[count];
        updatedStateCount.push(count);
      });
      setPermissionState(finalPermissions);
      setPermissionFormCount(updatedStateCount);
    } else if (site?.permissions && !site?.permissions.length) {
      setPermissionState({});
      setPermissionFormCount([0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site?.permissions]);

  useEffect(() => {
    if (site?.sso_role) {
      let isUser = site?.sso_role === "user";
      setRoleValue({
        _id: isUser ? 0 : 1,
        value: isUser ? "user" : "admin",
        name: isUser ? "User" : "Admin",
      });
    }
    setRole(site?.sso_role);
  }, [site?.sso_role]);

  useEffect(() => {
    if (site?.sso_trusted_attribute.includes("user.")) {
      setSsoTrustedAttributeAlt({
        _id: site?.sso_trusted_attribute,
        value: site?.sso_trusted_attribute,
        name: site?.sso_trusted_attribute,
      });
    }
    setSsoTrustedAttribute(site?.sso_trusted_attribute);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site?.sso_trusted_attribute]);

  useEffect(() => {
    setSsoTrustedAttributeValue(site?.sso_trusted_attribute_value);
  }, [site?.sso_trusted_attribute_value]);

  useEffect(() => {
    setTrustedAttribute(site?.trusted_attribute);
  }, [site?.trusted_attribute]);

  useEffect(() => {
    if (site?.groups && site?.groups.length > 0) {
      setSelectedGroups(site?.groups);
    }
  }, [site?.groups]);

  useEffect(() => {
    if (prevPermissionState) {
      const prevPermissionStateNew = Object.values(prevPermissionState);
      const permissionStateNew = Object.values(permissionState);
      const getTrueValue = permissionStateNew.filter((item) => item.is_default);
      if (getTrueValue.length > 1) {
        let index = prevPermissionStateNew.findIndex((item) => item.is_default);
        if (index !== -1) {
          if (permissionStateNew[index]) {
            permissionStateNew[index].is_default = false;
            const finalPermissions = {};
            Object.keys(permissionStateNew).forEach((count) => {
              finalPermissions[count] = permissionStateNew[count];
            });
            setPermissionState(finalPermissions);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissionState]);

  const addNewPermission = (e) => {
    e.preventDefault();
    if (permissionFormCount.length > 0) {
      setPermissionFormCount([...permissionFormCount, permissionFormCount.slice(-1)[0] + 1]);
    } else {
      setPermissionFormCount([0]);
    }
  };

  const updateSSORole = async () => {
    try {
      let permission = Object.values(permissionState);
      let permissionsArray = [];
      for (let i = 0; i < permission.length; i++) {
        const element = permission[i];
        permissionsArray.push({
          dashboard_id: element.dashboard_id,
          operator_id: element.operator_id,
          column_name: element.column_name,
          column_value: element.column_value,
          trusted_attribute: element.trusted_attribute,
          is_default: element.is_default,
        });
      }

      const message = await manageSiteSettings({
        sso_role: sso_role,
        groups: selectedGroups,
        trusted_attribute: trusted_attribute,
        sso_trusted_attribute: sso_trusted_attribute,
        sso_trusted_attribute_value: sso_trusted_attribute_value,
        permissions: permissionsArray,
      });
      toast.success(message);
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <div className="shadow bg-white rounded-md mt-4 p-6 flex flex-col gap-y-3">
        <h5 className="text-base font-medium mb-4">Map Attributes</h5>
        <div className="w-full h-[1px] bg-gray-200" />
        <div className="w-full flex flex-col gap-y-5">
          <div className="pl-7 flex flex-wrap items-end space-x-3 py-5">
            <div className="relative py-2.5 text-sm font-medium text-gray-700">Assign as</div>
            <div className="relative w-36">
              <SimpleDropdown
                label="Role"
                options={[
                  {
                    _id: 0,
                    value: "user",
                    name: "User",
                  },
                  {
                    _id: 1,
                    value: "admin",
                    name: "Admin",
                  },
                ]}
                selected={sso_value}
                setSelected={(e) => {
                  setRole(e.value);
                  setRoleValue(e);
                }}
              />
            </div>
            <div className="relative py-2.5 text-sm font-medium text-gray-700">when</div>
            <div className="relative w-48">
              <SimpleDropdown
                label="Trusted attribute"
                options={[
                  {
                    _id: "user.firstName",
                    value: "user.firstName",
                    name: "user.firstName",
                  },
                  {
                    _id: "user.lastName",
                    value: "user.lastName",
                    name: "user.lastName",
                  },
                  {
                    _id: "user.email",
                    value: "user.email",
                    name: "user.email",
                  },
                  {
                    _id: "user.username",
                    value: "user.username",
                    name: "user.username",
                  },
                  {
                    _id: "user.company",
                    value: "user.company",
                    name: "user.company",
                  },
                  {
                    _id: "user.department",
                    value: "user.department",
                    name: "user.department",
                  },
                ]}
                selected={sso_trusted_attribute_alt}
                setSelected={(e) => {
                  setSsoTrustedAttribute(e.value);
                  setSsoTrustedAttributeAlt(e);
                }}
              />
            </div>
            <div className="relative py-2.5 text-sm font-medium text-gray-700">equals</div>
            <div className="relative w-48">
              <div className="grow">
                <Input
                  name="sso_trusted_attribute_value"
                  label={""}
                  value={sso_trusted_attribute_value}
                  onChange={(e) => {
                    setSsoTrustedAttributeValue(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="w-full h-[1px] bg-gray-200" />
          <div className="pl-5 grid grid-cols-1 gap-4 w-full py-5">
            {permissionFormCount.map((count, i) => {
              return (
                <AddPermissionForm
                  usesTrustedValue={true}
                  key={i}
                  index={count}
                  dashboards={dashboards}
                  operators={operators?.map((operator) => {
                    return { ...operator, name: operatorNameConverter(operator.name)};
                  })}
                  formCount={permissionFormCount}
                  setFormCount={setPermissionFormCount}
                  state={permissionState}
                  setState={setPermissionState}
                />
              );
            })}
            <div className="flex justify-end">
              <button className="h-full inline-flex items-center justify-center rounded-md border px-4 py-2 text-regular font-medium focus:ring-2 focus:border-highlightColor bg-white text-gray-500 border-gray-400 hover:shadow-sm transition-all duration-75 sm:w-auto">
                <div className="flex items-center justif-center gap-x-2" onClick={addNewPermission}>
                  Add permission
                </div>
              </button>
            </div>
          </div>
          <div className="w-full h-[1px] bg-gray-200" />
          <div className="grid grid-cols-4 gap-4 py-5">
            <div className="pl-4 col-span-4 sm:col-span-3 lg:col-span-2">
              <MultiSelect
                options={[
                  {
                    _id: "user.firstName",
                    value: "user.firstName",
                    name: "user.firstName",
                  },
                  {
                    _id: "user.lastName",
                    value: "user.lastName",
                    name: "user.lastName",
                  },
                  {
                    _id: "user.email",
                    value: "user.email",
                    name: "user.email",
                  },
                  {
                    _id: "user.username",
                    value: "user.username",
                    name: "user.username",
                  },
                  {
                    _id: "user.company",
                    value: "user.company",
                    name: "user.company",
                  },
                  {
                    _id: "user.department",
                    value: "user.department",
                    name: "user.department",
                  },
                ]}
                title="Assign groups"
                selectedOptions={selectedGroups}
                setSelectedOptions={setSelectedGroups}
              ></MultiSelect>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end items-center w-full pt-6 space-x-4">
        <Button styleType="gray">Cancel</Button>
        <Button onClick={updateSSORole}>Save</Button>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
    dashboards: state.dashboards,
    operators: Object.values(state.operators),
  };
};
export default connect(mapStateToProps, { manageSiteSettings, getDashboardData, getOperatorData })(SSOServiceAttribute);
