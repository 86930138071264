import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { classNames } from "src/helpers/classNames";

const NavItem = ({ item, activeItem, onClick = () => {}, site }) => {
  return (
    <div key={item.name} onClick={() => onClick(item.name)}>
      <Link to={item.path}>
        <div
          className={classNames(
            "group flex items-center px-2 py-2 text-leftNavTextColor hover:text-leftNavActiveTextColor font-medium rounded-md transition-all duration-100",
            item.name === activeItem ? "bg-leftNavActiveColor text-leftNavActiveTextColor text-opacity-80" : "text-leftNavTextColor hover:text-leftNavActiveTextColor hover:bg-leftNavActiveColor hover:bg-opacity-80"
          )}
        >
          <div
            className="hover:bg-leftNavActiveColor mr-4 nav-icon flex-shrink-0 h-6 w-6"
            dangerouslySetInnerHTML={{
              __html: `<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
            ${item.icon}
          </svg>`,
            }}
          />
          {item.name}
        </div>
      </Link>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
  };
};

export default connect(mapStateToProps, null)(NavItem);
