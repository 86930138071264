import React, { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { changePassword } from "src/actions/auth";

const ResetPasswordContent = ({ token, changePassword }) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const navigate = useNavigate();

  const resetPassword = async () => {
    try {
      const message = await changePassword({
        password,
        confirm_password: confirmPassword,
        token,
      });

      toast.success(message);

      return navigate("/");
    } catch (err) {
      toast.error(err.message);
    }
  };

  return (
    <>
      <div className="col-lg-12">
        <div className="form-group">
          <input type="password" className="form-control" placeholder="Enter Password" value={password} onChange={(e) => setPassword(e.target.value)} />
        </div>
      </div>

      <div className="col-lg-12">
        <div className="form-group">
          <input type="password" className="form-control " placeholder="Enter Confirm Password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
        </div>
      </div>

      <div className="col-lg-12">
        <div className="form-action mt-2">
          <button type="button" className="btn-action-primary" onClick={resetPassword}>
            Reset Password
          </button>
        </div>
      </div>
    </>
  );
};

export default connect(null, { changePassword })(ResetPasswordContent);
