import { SearchIcon } from "@heroicons/react/solid";

const Search = ({ keyword, setKeyword }) => {
  return (
    <div className="w-full sm:w-[290px] xl:w-[400px] flex">
      <label htmlFor="search" className="sr-only">
        Search
      </label>
      <div className="relative rounded-md shadow-sm w-full flex">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none" aria-hidden="true">
          <SearchIcon className="mr-3 h-4 text-gray-400" aria-hidden="true" />
        </div>
        <input
          type="text"
          name="search"
          id="search"
          value={keyword}
          className="block w-full pl-9 border-gray-300 text-sm rounded-md focus:ring-0 focus:border-highlightColor"
          placeholder="Search"
          onChange={(e) => setKeyword(e.target.value)}
        />
      </div>
    </div>
  );
};

export default Search;
