import { fetchData } from "src/async/fetch";
import { hostUrl } from "src/config/host";
import { GET_SITE_SETTINGS_DATA, GET_DATASET_USERS, GET_DATASET_PORTAL_USERS } from "./type";

export const manageSiteSettings = (body) => async (dispatch) => {
  try {
    const res = await fetchData("POST", `${hostUrl}/site-settings/manage`, body);
    const data = await res.json();

    if (data.status === 200) {
      dispatch({ type: GET_SITE_SETTINGS_DATA, payload: data.data });
      return data.message;
    } else {
      throw new Error(data.message);
    }
  } catch (err) {
    throw new Error(err.message);
  }
};

export const getSiteSettingsCache = () => (dispatch) => {
  const checkData = localStorage.getItem("siteSettings");

  if (checkData) {
    const cacheData = JSON.parse(localStorage.getItem("siteSettings"));
    dispatch({ type: GET_SITE_SETTINGS_DATA, payload: cacheData });
  }
};

export const getSiteSettings =
  (body, signal = null) =>
  async (dispatch) => {
    try {
      const res = await fetchData("POST", `${hostUrl}/site-settings`, body, signal);
      const data = await res.json();

      if (data.status === 200) {
        dispatch({ type: GET_SITE_SETTINGS_DATA, payload: data.data });
        localStorage.setItem("siteSettings", JSON.stringify(data.data));
        return data.data;
      } else {
        throw new Error(data.message);
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };

export const getDatasetAccess = async (body = null, signal, headers, url) => {
  try {
    const res = await fetchData("POST", url, body, signal, headers);
    const data = await res.json();

    if (!data?.access_token) {
      throw new Error(JSON.stringify(data));
    } else {
      return data;
    }
  } catch (err) {
    return { status: 400, message: err.message };
  }
};
export const pushDataPotal = async (body = null, signal, headers, url) => {
  try {
    const res = await fetchData("PUT", url, body, signal, headers);
    const data = await res.json();

    if (!data?.access_token) {
      throw new Error(JSON.stringify(data));
    } else {
      return data;
    }
  } catch (err) {
    return { status: 400, message: err.message };
  }
};

export const createDataset = async (body = null, signal, headers, url) => {
  try {
    const res = await fetchData("POST", url, body, signal, headers);
    const data = await res.json();

    if (!data?.access_token) {
      throw new Error(JSON.stringify(data));
    } else {
      return data;
    }
  } catch (err) {
    return err.message;
  }
};

export const getDatasetQuery = async (body, signal, headers, url) => {
  try {
    const res = await fetchData("POST", url, body, signal, headers);
    const data = await res.json();

    if (!data?.datasource) {
      throw new Error(`Error: ${JSON.stringify(data)}`);
    } else {
      // dispatch({ type: GET_DATASET_QUERY, payload: JSON.stringify(data) });
      return data;
    }
  } catch (err) {
    throw new Error(err.message);
  }
};

export const manageDatasetUsers = (body, signal) => async (dispatch) => {
  try {
    const res = await fetchData("POST", `${hostUrl}/dataset-user/row/manage`, body, signal);
    const resData = await res.json();

    if (resData.status !== 200) {
      throw new Error(resData.message);
    } else {
      dispatch({ type: GET_DATASET_USERS, payload: resData.data });
      return resData;
    }
  } catch (err) {
    throw new Error(err.message);
  }
};

export const manageDatasetPortalUsers = (body, signal) => async (dispatch) => {
  try {
    const res = await fetchData("POST", `${hostUrl}/dataset-portal-user/row/manage`, body, signal);
    const resData = await res.json();

    if (resData.status !== 200) {
      throw new Error(resData.message);
    } else {
      dispatch({ type: GET_DATASET_PORTAL_USERS, payload: resData.data });
      return resData;
    }
  } catch (err) {
    throw new Error(err.message);
  }
};

export const getPortalDatasetData = (body, signal) => async (dispatch) => {
  try {
    const res = await fetchData("POST", `${hostUrl}/dataset-portal-user/row/list`, body, signal);
    const resData = await res.json();
    if (resData.status !== 200) {
      throw new Error(resData.message);
    } else {
      dispatch({ type: GET_DATASET_PORTAL_USERS, payload: resData.data });
      return resData;
    }
  } catch (err) {
    throw new Error(err.message);
  }
};

export const getClientData = async (body, signal) => {
  try {
    const res = await fetchData("POST", `${hostUrl}/site-settings/decrypt`, body, signal);
    const resData = await res.json();

    if (resData.status !== 200) {
      throw new Error(resData.message);
    } else {
      return resData.data;
    }
  } catch (err) {
    throw new Error(err.message);
  }
};
