import React, { useState, Fragment, useRef } from "react";
import { connect } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon, ChevronDownIcon } from "@heroicons/react/outline";
import Input from "src/components/Shared/Forms/Input";
import { addGroup, editGroupData } from "src/actions/user";
import { toast } from "react-toastify";
import { useEffect } from "react";
import AddPermissionForm from "../Groups/AddPermissionForm";
import Button from "src/components/Shared/Button";
import ProfileImage from "./Edit/ProfileImage";
import { operatorNameConverter } from "src/helpers/operatorNameConverter";
import { noBusiness } from "src/config/host";
import { classNames } from "src/helpers/classNames";

const GroupModal = ({
  isOpen = false,
  closeModal = () => {},
  submitText,
  cancelText,
  title,
  addGroup,
  children,
  onSubmit = () => {},
  removeSubmit = false,
  removeClose = false,
  editGroupData,
  ...props
}) => {
  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [permissionFormCount, setPermissionFormCount] = useState([0]);
  const [permissionState, setPermissionState] = useState({});
  const prevPermissionState = usePrevious(permissionState);
  const [group, setGroup] = useState({});
  const [advancedFeatures, setAdvancedFeatures] = useState(false);

  const createGroup = async () => {
    try {
      const data = await addGroup({
        group_name: name,
        group_image: image,
        permissions: Object.values(permissionState),
      });
      setName("");
      setImage("");
      setPermissionState({});
      props.setIsOpen(false);
      props.setAddGroupStatus(false);
      props.setEditGroupStatus(false);
      props.setRefresh(data.data._id);
      toast.success(data.message);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const editGroup = async () => {
    try {
      const data = await editGroupData({
        id: group?._id,
        group_name: name,
        group_image: image,
        name,
        permissions: Object.values(permissionState),
      });
      setName("");
      setImage("");
      setPermissionState({});
      props.setIsOpen(false);
      props.setAddGroupStatus(false);
      props.setEditGroupStatus(false);
      props.setRefresh(data.data._id);
      toast.success(data.message);
      props.setEditId(null);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const groupModalSubmit = async () => {
    if (props.addGroupStatus) {
      await createGroup();
    } else if (props.editGroupStatus) {
      await editGroup();
    }

    return;
  };

  useEffect(() => {
    if (props?.editId && props.groups.length) {
      const findGroup = props.groups.find((group) => group._id === props?.editId);
      setGroup(findGroup);
      if (findGroup && findGroup.permissions && findGroup.permissions.length) {
        const updatedStateCount = [];

        const finalPermissions = {};

        findGroup.permissions.forEach((permission, i) => {
          finalPermissions[i] = permission;
          updatedStateCount.push(i);
        });

        setPermissionState(finalPermissions);
        setPermissionFormCount(updatedStateCount);
      }

      setName(findGroup?.name);
      setImage(findGroup?.image);
    } else {
      setGroup({});
      setPermissionState({});
      setPermissionFormCount([0]);
      setName("");
      setImage("");
      // const uid = v4();
      // setPermissionState({
      //   [uid]: {
      //     _id: uid,
      //     dashboard_id: null,
      //     operator_id: null,
      //     column_name: '',
      //     column_value: '',
      //     is_default: false
      //   }
      // })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.editId]);

  useEffect(() => {
    if (prevPermissionState) {
      const prevPermissionStateNew = Object.values(prevPermissionState);
      const permissionStateNew = Object.values(permissionState);
      const getTrueValue = permissionStateNew.filter((item) => item.is_default);
      if (getTrueValue.length > 1) {
        let index = prevPermissionStateNew.findIndex((item) => item.is_default);
        if (index !== -1) {
          if (permissionStateNew[index]) {
            permissionStateNew[index].is_default = false;
            const finalPermissions = {};
            Object.keys(permissionStateNew).forEach((count) => {
              finalPermissions[count] = permissionStateNew[count];
            });
            setPermissionState(finalPermissions);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissionState]);

  const addNewPermission = (e) => {
    e.preventDefault();
    if (permissionFormCount.length > 0) {
      setPermissionFormCount([...permissionFormCount, permissionFormCount.slice(-1)[0] + 1]);
    } else {
      setPermissionFormCount([0]);
    }
  };

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  }

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-30"
        onClose={() => {
          props.setIsOpen(!isOpen);
          props.setEditId(null);
        }}
      >
        <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:max-w-3xl sm:w-full p-0">
                <div className="flex justify-between py-4 px-4">
                  <Dialog.Title as="h3" className="text-lg text-left leading-6 font-medium text-gray-900">
                    {title}
                  </Dialog.Title>
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
                    onClick={() => {
                      props.setIsOpen(!isOpen);
                      props.setEditId(null);
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                <div className="px-4">
                  {/* Group name */}
                  <div className="flex flex-wrap -mx-2">
                    <div className="w-full px-2 mb-4">
                      <Input name="name" label="Name" value={name} onChange={(e) => setName(e.target.value)} />
                    </div>
                  </div>
                  <div className="flex justify-end">
                    <div
                      onClick={() => setAdvancedFeatures(!advancedFeatures)}
                      className="-mt-[10px] py-1 px-2 rounded-md hover:bg-gray-50 cursor-pointer inline-flex items-center gap-x-1 text-gray-300 transition-all duration-200 hover:text-gray-400"
                    >
                      <p>Advanced features</p>
                      <ChevronDownIcon className={classNames("h-5", advancedFeatures ? "" : "-rotate-90")} />
                    </div>
                  </div>
                  {/* Group image */}
                  <div className={classNames("ml-4 mb-4 transition-all duration-150 overflow-hidden", advancedFeatures ? "max-h-[120px] opacity-100" : "max-h-[0px] opacity-20")}>
                    <div className="flex flex-wrap -mx-2">
                      <div className="w-full px-2 mb-4">
                        <p className="text-gray-300 text-sm">*Individuals assigned to this group will show this logo instead of company logo</p>
                        <ProfileImage
                          baseImage={noBusiness}
                          image={image}
                          user={group}
                          setImage={(value) => {
                            setImage(value);
                            setGroup({ ...group, image: value });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <h4 className="flex justify-between text-sm font-medium text-gray-700 mb-1">Add permissions</h4>
                  <div className="p-3 bg-gray-50 min-h-[174px] rounded-md flex flex-col gap-y-2">
                    {permissionFormCount.length ? (
                      permissionFormCount.map((count, i) => {
                        return (
                          <div className="bg-white rounded-md" key={count}>
                            <AddPermissionForm
                              key={i}
                              index={count}
                              dashboards={props.dashboards}
                              permissions={group?.permissions}
                              operators={props.operators?.map((operator) => {
                                return { ...operator, name: operatorNameConverter(operator.name), id: operator._id };
                              })}
                              formCount={permissionFormCount}
                              setFormCount={setPermissionFormCount}
                              state={permissionState}
                              entireState={permissionState}
                              setState={setPermissionState}
                              name={name}
                              setName={setName}
                              editId={props.editId}
                            />

                            {i >= 1 && (
                              <div className="flex justify-end">
                                <button
                                  type="button"
                                  style={{ display: "none" }}
                                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 mr-2 bg-highlightColor text-base font-medium text-white  focus:outline-none  sm:w-auto sm:text-sm"
                                  onClick={() => {
                                    // setFormCount(formCount - 1)
                                    const currState = permissionState;
                                    delete currState[i];
                                    setPermissionState(currState);
                                  }}
                                >
                                  Remove
                                </button>
                              </div>
                            )}
                          </div>
                        );
                      })
                    ) : (
                      <div className="w-full mt-20 flex items-center justify-center text-gray-300">No permissions assigned</div>
                    )}
                  </div>
                  <div className="w-full flex justify-end mb-9 mt-3">
                    <Button styleType="secondary" onClick={addNewPermission}>
                      Add Permission
                    </Button>
                  </div>
                </div>
                <div className="p-4 flex justify-end space-x-4">
                  {!removeClose && (
                    <button
                      type="button"
                      className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
                      onClick={() => {
                        props.setIsOpen(!isOpen);
                        props.setEditId(null);
                      }}
                    >
                      {cancelText}
                    </button>
                  )}
                  {!removeSubmit && (
                    <button
                      type="button"
                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-highlightColor text-base font-medium text-white  focus:outline-none  sm:w-auto sm:text-sm"
                      onClick={() => {
                        onSubmit();
                        groupModalSubmit();
                      }}
                    >
                      {submitText}
                    </button>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const mapStateToProps = (state) => {
  return {
    dashboards: Object.values(state.dashboards),
    operators: Object.values(state.operators),
    groups: Object.values(state.groups),
  };
};

export default connect(mapStateToProps, { addGroup, editGroupData })(GroupModal);
