import { useRef, useState } from "react";
import { ChromePicker } from "react-color";
import { classNames } from "src/helpers/classNames";
import useOnClickOutside from "src/hooks/useClickOutside";

const SiteColorItem = ({ color = "", onChange, name }) => {
  const ref = useRef();

  const [showColorModal, setShowColorModal] = useState(false);

  useOnClickOutside(ref, () => setShowColorModal(false));
  return (
    <div className="py-0 relative">
      <div className="text-black font-semibold text-base sm:text-xs mb-1 leading-none uppercase">{name}</div>
      <div className="flex items-center">
        <div onClick={() => setShowColorModal(true)} className={classNames("h-8 sm:h-6 w-2/12 rounded-md border border-gray-200 cursor-pointer")} style={{ backgroundColor: color }}></div>
        <div className="flex w-10/12">
          <input
            className="border-0 px-2 py-1 rounded w-full h-8 sm:h-6 focus:outline-none text-sm bg-transparent"
            value={color} 
            onClick={() => setShowColorModal(true)}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                setShowColorModal(false);
              }
            }}
            onChange={(e) => onChange("#" + e.target.value.split("#")[1])}
          />
        </div>
      </div>
      <div ref={ref} className={`${showColorModal ? "absolute bg-white z-30 left-[9px] top-14" : "hidden"}`}>
        <ChromePicker
          color={color}
          onChangeComplete={(color) => {
            setShowColorModal(true);
            onChange(color.hex);
          }}
        />
      </div>
    </div>
  );
};

export default SiteColorItem;
