import { XIcon } from "@heroicons/react/solid";
import { useEffect, useState } from "react";
import Input from "src/components/Shared/Forms/Input";
import SimpleDropdown from "src/components/Shared/Forms/SimpleDropdown";
import Toggle from "src/components/Shared/Toggle";
import { classNames } from "src/helpers/classNames";

const AddPermissionForm = ({ dashboards, isDefault, updateDefaultKey, operators, state, setState, index, ...props }) => {
  const [dashboardId, setDashboardId] = useState({ _id: -1, name: "None selected" });
  const [operatorId, setOperatorId] = useState({ _id: -1, name: "None selected" });
  const [columnName, setColumnName] = useState("");
  const [columnValue, setColumnValue] = useState("");

  const removeForm = (e) => {
    e.preventDefault();
    delete state[index];
    setState(state);

    const availableForms = props.formCount.filter((count) => count !== index);
    props.setFormCount(availableForms);
  };

  const updateForm = (e, name, next) => {
    // eslint-disable-next-line
    next({ _id: e._id, name: e.name });
    if (e._id !== -1) {
      handleState(name, e._id || e._id);
    } else {
      handleState(name, "");
    }
  };

  const handleState = (name, value) => {
    setState({
      ...state,
      [index]: {
        ...state[index],
        [name]: value,
      },
    });
  };

  useEffect(() => {
    setState({
      ...state,
      [index]: {
        dashboard_id: state[index]?.dashboard_id,
        operator_id: state[index]?.operator_id,
        column_name: state[index]?.column_name,
        column_value: state[index]?.column_value,
        is_default: state[index]?.is_default,
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state[index] && state[index].dashboard_id) {
      const dashboardIndex = dashboards.findIndex((item) => item._id === state[index].dashboard_id);
      if (dashboardIndex !== -1) {
        setDashboardId(dashboards[dashboardIndex]);
        updateForm(dashboards[dashboardIndex], "dashboard_id", setDashboardId);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state[index]?.dashboard_id]);

  useEffect(() => {
    if (state[index] && state[index].operator_id) {
      const operatorIndex = operators.findIndex((item) => item._id === state[index].operator_id);
      if (operatorIndex !== -1) {
        setOperatorId(operators[operatorIndex]);
        updateForm(operators[operatorIndex], "operator_id", setOperatorId);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state[index]?.operator_id]);

  useEffect(() => {
    if (state[index] && state[index].column_name) {
      setColumnName(state[index].column_name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state[index]?.column_name]);

  useEffect(() => {
    if (state[index] && state[index].column_value) {
      setColumnValue(state[index].column_value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state[index]?.column_value]);

  useEffect(() => {
    setState({
      ...state,
      [index]: {
        ...state[index],
        is_default: state[index]?.is_default,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state[index]?.is_default]);

  const setCheckboxOpen = (e) => {
    setState({
      ...state,
      [index]: {
        ...state[index],
        is_default: e,
      },
    });
  };

  return (
    <>
      <div className="flex flex-col w-full items-end relative border-[1px] border-gray-200 rounded-md px-3 py-4 overflow-visible">
        <button type="button" className="absolute top-3.5 right-3.5 bg-white rounded-md text-red-400 hover:text-red-500 focus:outline-none" onClick={removeForm}>
          <span className="sr-only">Close</span>
          <XIcon className="h-6 w-6" aria-hidden="true" />
        </button>

        <div className="w-full flex flex-wrap gap-y-4">
          {/* Dashboard */}
          <div className="w-2/3 px-2">
            <SimpleDropdown label="Dashboard" options={dashboards} selected={dashboardId} setSelected={(e) => updateForm(e, "dashboard_id", setDashboardId)} />
          </div>
          <div className="w-1/3 flex justify-center items-end pb-1">
            <div className="inline-flex space-x-2 items-center py-1.5 px-2 rounded-full bg-gray-50">
              <Toggle value={state[index]?.is_default || false} setValue={setCheckboxOpen} />
              <span className={classNames("text-sm font-medium", state[index]?.is_default ? "text-highlightColor" : "text-gray-400")}>{state[index]?.is_default ? "Default" : "Set default"}</span>
            </div>
          </div>

          <div className="w-full flex">
            {/* Column name */}
            <div className="grow px-2">
              <Input
                name="column-name"
                label={"Column name"}
                value={columnName}
                onChange={(e) => {
                  handleState("column_name", e.target.value);
                  setColumnName(e.target.value);
                }}
              />
            </div>

            {/* Operator */}
            <div className="w-[110px] px-2">
              <SimpleDropdown label="Operator" options={operators} selected={operatorId} setSelected={(e) => updateForm(e, "operator_id", setOperatorId)} />
            </div>

            {/* Column value */}
            <div className="grow px-2">
              <Input
                name="column-value"
                label="Column value"
                value={columnValue}
                onChange={(e) => {
                  handleState("column_value", e.target.value);
                  setColumnValue(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddPermissionForm;
