import moment from "moment";
import { connect } from "react-redux";
import { clearUserDetails, manageUserStatus } from "src/actions/user";
import { noProfile } from "src/config/host";

const PortalUserEntry = ({ index, checkedState, manageUserSelect, user, clearUserDetails, manageUserStatus, userAuth, site, ...props }) => {
  const formatMemberDate = (date) => {
    if (!date) return "";

    return moment(new Date(date)).format("MM/DD/YYYY");
  };

  return (
    <tr key={user?._id}>
      <td className="whitespace-nowrap py-3 pl-4 pr-3 text-sm sm:pl-6">
        <input type="checkbox" checked={checkedState[index] || false} onChange={(e) => manageUserSelect(e, e.target.checked, index)} />
      </td>
      <td className="whitespace-nowrap py-3 pl-4 pr-3 text-sm sm:pl-6">
        <div className="flex items-center">
          <div className="hidden sm:block h-10 w-10 flex-shrink-0 overflow-hidden">
            {user?.image ? (
              <img className="object-cover w-full h-full" src={user?.image} alt="profile" onError={(e) => (e.target.src = `${noProfile}`)} />
            ) : (
              <img className="object-cover w-full h-full" src={noProfile} alt="profile" onError={(e) => (e.target.src = `${noProfile}`)} />
            )}
          </div>
          <div className="sm:ml-4">
            <div className="font-medium text-gray-900 flex items-center gap-x-2">
              {user?.name}
              {user?.default_user && <span className="bg-gray-100 text-gray-400 rounded-full px-2 py-[1px]">Super Admin</span>}
            </div>

            <div className="text-gray-500">
              <p className="flex lg:hidden">{user?.email}</p>
              <p className="hidden lg:flex">
                {user.insert_type === "manual" ? "Manually" : user?.insert_type === "sso" ? "SSO" : "API"} created {formatMemberDate(user.created_at)}
              </p>
            </div>
          </div>
        </div>
      </td>
      <td className="hidden lg:table-cell whitespace-nowrap px-3 py-3 text-sm text-gray-500">
        <div className="text-gray-900">{user?.email}</div>
      </td>
      <td className="flex max-h-[73.5px] px-3 py-4 text-sm text-gray-500">
        {user?.permissions && user.permissions.length > 0 && (
          <p className="bg-gray-100 rounded-full m-0 px-[7px] py-[3px] flex items-center">
            {user.permissions.length} permission{user.permissions.length > 1 ? "s" : ""}
          </p>
        )}
      </td>
    </tr>
  );
};

const mapStateToProps = (state) => {
  return {
    userAuth: state.auth.user,
    site: state.site,
  };
};

export default connect(mapStateToProps, { clearUserDetails, manageUserStatus })(PortalUserEntry);
