import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import PageHeader from "src/components/Shared/PageHeader";
import CardSSOForm from "./CardSSOForm";
import SSOEntry from "./SSOEntry";
import SSOList from "./SSOList";
import { getSSODataCount, deleteSso } from "src/actions/sso";
import { toast } from "react-toastify";
import DefaultModal from "src/components/Shared/DefaultModal";
import NavigationCrumbs from "src/components/Shared/NavigationCrumbs";

const ListSSO = ({ sso, getSSODataCount, deleteSso }) => {
  const [loaded, setLoaded] = useState(false);
  const [count, setCount] = useState(0);
  const [perPage, setPerpage] = useState("10");
  const [offset, setOffset] = useState(0);
  const [pages, setPages] = useState(1);
  const [deleteId, setDeleteId] = useState(null);

  useEffect(() => {
    const ac = new AbortController();

    const loadSSO = async () => {
      setLoaded(false);
      const params = { limit: +perPage, offset: offset * +perPage };

      try {
        const ssoCount = await getSSODataCount(params, ac.signal);
        setCount(ssoCount);

        const availablePages = Math.ceil(ssoCount / +perPage);
        setPages(availablePages);
        setLoaded(true);
      } catch (err) {
        setLoaded(true);
        console.dir(err.message);
      }
    };

    loadSSO();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getOffsetPageCount = (offset, perPage) => {
    return !offset ? offset + 1 : offset * +perPage + 1;
  };

  const removeSSO = async (e) => {
    e.preventDefault();

    try {
      const message = await deleteSso(deleteId);
      setDeleteId(null);
      toast.success(message);
    } catch (err) {
      toast.error(err.message);
    }
  };

  return (
    <>
      <PageHeader>SSO</PageHeader>

      <CardSSOForm sso={sso} setLoaded={setLoaded} perPage={perPage} setPerpage={setPerpage} offset={offset} setCount={setCount} setPages={setPages} setOffset={setOffset} />
      <DefaultModal isOpen={!!deleteId} closeModal={() => setDeleteId(null)} onSubmit={removeSSO} submitText="Delete" cancelText="Cancel" title="Delete sso">
        <div className="whitespace-nowrap text-sm text-gray-500 grid gap-y-8">Are you sure you want to delete {sso.find((item) => item._id === deleteId)?.provider_name}?</div>
      </DefaultModal>
      <SSOList setDeleteId={setDeleteId} deleteId={deleteId} loaded={loaded} ssoExist={sso.length}>
        {sso.length > 0 && sso.map((item) => <SSOEntry key={item._id} sso={item} deleteId={deleteId} setDeleteId={setDeleteId} />)}
      </SSOList>
      {count > perPage && sso.length > 0 && loaded && <NavigationCrumbs getOffsetPageCount={getOffsetPageCount} offset={offset} perPage={perPage} count={count} pages={pages} setOffset={setOffset} />}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    sso: Object.values(state.sso),
  };
};

export default connect(mapStateToProps, { getSSODataCount, deleteSso })(ListSSO);
