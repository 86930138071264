import { hostUrl } from "src/config/host";
import { fetchData } from "src/async/fetch";
import { GET_OPERATOR_DATA } from "./type";

export const getOperatorData =
  (body, signal = null) =>
  async (dispatch) => {
    try {
      const res = await fetchData("POST", `${hostUrl}/operator/list`, body, signal);
      const data = await res.json();

      if (data.status === 200) {
        dispatch({ type: GET_OPERATOR_DATA, payload: data.data });
        return data.message;
      } else {
        throw new Error(data.message);
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
