import { Navigate, Route, Routes } from "react-router-dom";
import Activity from "src/components/Activity/ActivitySection";
import LoginContent from "src/components/Auth/LoginContent";
import { FeatureFlagRoute } from "src/components/FeatureFlags/FeatureFlagRoute";
import GroupList from "src/components/Groups/GroupSection";
import ListSSO from "src/components/SSO/ListSSO";
import AdminUsers from "src/pages/adminUsers";
import BulkUserAdd from "src/pages/bulkUserAdd";
import ChangePassword from "src/pages/changePassword";
import Dashboards from "src/pages/dashboards";
import DatasetQuery from "src/pages/datasetQuery";
import ForgotPassword from "src/pages/forgotPassword";
import ManageUser from "src/pages/manageUser";
import ManageUserEdit from "src/pages/manageUserEdit";
import ResetPassword from "src/pages/resetPassword";
import RouteNotFound404 from "src/pages/routeNotFound404";
import SiteSettings from "src/pages/siteSettings";
import SSOServiceProvider from "src/pages/ssoDetails";
import Users from "src/pages/users";
import VerifyToken from "src/pages/verifyToken";
import FreeAuthRoute from "src/routes/FreeAuthRoute";
import ProtectedRoute from "src/routes/ProtectedRoute";

//Router
export default (
  <Routes>
    <Route
      path="/"
      element={
        <FreeAuthRoute>
          <LoginContent />
        </FreeAuthRoute>
      }
    />
    <Route
      path="/login"
      element={
        <FreeAuthRoute>
          <LoginContent />
        </FreeAuthRoute>
      }
    />
    <Route
      path="/sso-logout"
      element={
        <FreeAuthRoute>
          <LoginContent />
        </FreeAuthRoute>
      }
    />
    <Route
      path="/forgot-password"
      element={
        <FreeAuthRoute>
          <ForgotPassword />
        </FreeAuthRoute>
      }
    />
    <Route
      path="/activity"
      element={
        <ProtectedRoute>
          <FeatureFlagRoute feature={"SSO"}>
            <Activity />
          </FeatureFlagRoute>
        </ProtectedRoute>
      }
    />
    <Route
      path="/verify-token/:token"
      element={
        <FreeAuthRoute>
          <VerifyToken />
        </FreeAuthRoute>
      }
    />
    <Route
      path="/reset-password/:token"
      element={
        <FreeAuthRoute>
          <ResetPassword />
        </FreeAuthRoute>
      }
    />

    <Route
      path="/groups"
      element={
        <ProtectedRoute>
          <GroupList />
        </ProtectedRoute>
      }
    />

    <Route
      path="/users"
      element={
        <ProtectedRoute>
          <Users />
        </ProtectedRoute>
      }
    />
    <Route
      path="/admins"
      element={
        <ProtectedRoute>
          <AdminUsers />
        </ProtectedRoute>
      }
    />
    <Route
      path="/portal-users"
      element={
        <ProtectedRoute>
          {/* <PortalUsers /> */}
          <DatasetQuery portal={true} />
        </ProtectedRoute>
      }
    />
    <Route
      path="/dashboards"
      element={
        <ProtectedRoute>
          <Dashboards />
        </ProtectedRoute>
      }
    />
    <Route
      path="/sso"
      element={
        <ProtectedRoute>
          <FeatureFlagRoute feature={"SSO"}>
            <ListSSO />
          </FeatureFlagRoute>
        </ProtectedRoute>
      }
    />
    <Route
      path="/sso/add"
      element={
        <ProtectedRoute>
          <FeatureFlagRoute feature={"SSO"}>
            <SSOServiceProvider />
          </FeatureFlagRoute>
        </ProtectedRoute>
      }
    />
    <Route
      path="/sso/edit/:id"
      element={
        <ProtectedRoute>
          <FeatureFlagRoute feature={"SSO"}>
            <SSOServiceProvider />
          </FeatureFlagRoute>
        </ProtectedRoute>
      }
    />
    <Route
      path="/site-settings"
      element={
        <ProtectedRoute>
          <SiteSettings />
        </ProtectedRoute>
      }
    />
    <Route
      path="/manage-user"
      element={
        <ProtectedRoute>
          <ManageUser />
        </ProtectedRoute>
      }
    />
    <Route
      path="/manage-admin"
      element={
        <ProtectedRoute>
          <ManageUser />
        </ProtectedRoute>
      }
    />
    <Route
      path="/update-user/:id"
      element={
        <ProtectedRoute>
          <ManageUserEdit />
        </ProtectedRoute>
      }
    />
    <Route
      path="/update-admin/:id"
      element={
        <ProtectedRoute>
          <ManageUserEdit />
        </ProtectedRoute>
      }
    />
    <Route
      path="/change-password"
      element={
        <ProtectedRoute>
          <ChangePassword />
        </ProtectedRoute>
      }
    />

    <Route
      path="/bulk-user-upload"
      element={
        <ProtectedRoute>
          <BulkUserAdd />
        </ProtectedRoute>
      }
    />

    <Route
      path="/datasetusers"
      element={
        <ProtectedRoute>
          <DatasetQuery />
        </ProtectedRoute>
      }
    />
    <Route path="/404" element={<RouteNotFound404 />} />
    <Route path="*" element={<Navigate replace to="/404" />} />
  </Routes>
);
