import React from "react";
import NoData from "src/components/Shared/NoData";
import Preloader from "src/components/Shared/Preloader";
import Table from "src/components/Shared/Table/Table";
import TableBody from "src/components/Shared/Table/TableBody";
import TableHeader from "src/components/Shared/Table/TableHeader";

const GroupList = ({ ...props }) => {
  return (
    <div className="flex flex-col">
      <Table>
        <TableHeader>
          <tr>
            <th scope="col" className="rounded-l-xl overflow-hidden py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
              Name
            </th>
            <th scope="col" className="rounded-r-xl overflow-hidden  hidden lg:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900"></th>
          </tr>
        </TableHeader>
        <TableBody>
          {props.loaded && props.children}
          {props.loaded && !props.dataExists ? (
            <tr>
              <td colSpan="5">
                <NoData />
              </td>
            </tr>
          ) : !props.loaded ? (
            <tr>
              <td colSpan="5">
                <Preloader />
              </td>
            </tr>
          ) : null}
        </TableBody>
      </Table>
    </div>
  );
};

export default GroupList;
