import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import PageHeader from "src/components/Shared/PageHeader";
import CardGroupForm from "./CardGroupForm";
import GroupEntry from "./GroupEntry";
import GroupList from "./GroupList";
import { getGroupDataCount, deleteGroupData } from "src/actions/user";
import { getDashboardData } from "src/actions/dashboard";
import { getOperatorData } from "src/actions/operator";
import { toast } from "react-toastify";
import DefaultModal from "src/components/Shared/DefaultModal";
import GroupModal from "src/components/Users/GroupModal";
import NavigationCrumbs from "src/components/Shared/NavigationCrumbs";

const GroupSection = ({ groups, getGroupDataCount, deleteGroupData, ...props }) => {
  const [loaded, setLoaded] = useState(false);
  const [groupModalOpen, setGroupModalOpen] = useState(false);
  const [count, setCount] = useState(0);
  const [perPage, setPerpage] = useState("10");
  const [offset, setOffset] = useState(0);
  const [pages, setPages] = useState(1);
  const [deleteId, setDeleteId] = useState(null);
  const [addGroupStatus, setAddGroupStatus] = useState(false);
  const [editGroupStatus, setEditGroupStatus] = useState(false);
  const [editId, setEditId] = useState(null);

  useEffect(() => {
    const ac = new AbortController();

    const loadGroup = async () => {
      setLoaded(false);
      const params = { limit: +perPage, offset: offset * +perPage };

      try {
        const groupCount = await getGroupDataCount(params, ac.signal);
        setCount(groupCount);

        const availablePages = Math.ceil(groupCount / +perPage);
        setPages(availablePages);
        setLoaded(true);
      } catch (err) {
        setLoaded(true);
        console.dir(err.message);
      }
    };

    loadGroup();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const ac = new AbortController();

    const loadDashboards = async () => await props.getDashboardData({}, ac.signal);
    loadDashboards();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const ac = new AbortController();

    const loadOperators = async () => await props.getOperatorData({}, ac.signal);
    loadOperators();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getOffsetPageCount = (offset, perPage) => {
    return !offset ? offset + 1 : offset * +perPage + 1;
  };

  const removeGroup = async (e) => {
    e.preventDefault();

    try {
      const message = await deleteGroupData(deleteId);
      setDeleteId(null);
      toast.success(message);
    } catch (err) {
      toast.error(err.message);
    }
  };

  return (
    <>
      <PageHeader>Groups</PageHeader>

      <CardGroupForm
        groups={groups}
        setLoaded={setLoaded}
        perPage={perPage}
        setPerpage={setPerpage}
        offset={offset}
        setCount={setCount}
        setPages={setPages}
        setOffset={setOffset}
        setGroupModalOpen={setGroupModalOpen}
        setAddGroupStatus={setAddGroupStatus}
        setEditGroupStatus={setEditGroupStatus}
        setEditId={setEditId}
      />
      <DefaultModal isOpen={!!deleteId} closeModal={() => setDeleteId(null)} onSubmit={removeGroup} submitText="Delete" cancelText="Cancel" title="Delete Group">
        <div className="whitespace-nowrap text-sm text-gray-500 grid gap-y-8">Are you sure you want to delete {groups.find((item) => item._id === deleteId)?.name}?</div>
      </DefaultModal>
      <GroupList setDeleteId={setDeleteId} deleteId={deleteId} loaded={loaded} dataExists={groups.length > 0}>
        {groups.length > 0 &&
          groups.map((item) => (
            <GroupEntry
              key={item._id}
              group={item}
              deleteId={deleteId}
              setDeleteId={setDeleteId}
              setEditId={setEditId}
              setGroupModalOpen={setGroupModalOpen}
              setAddGroupStatus={setAddGroupStatus}
              setEditGroupStatus={setEditGroupStatus}
            />
          ))}
      </GroupList>
      {count > perPage && groups.length > 0 && loaded && (
        <NavigationCrumbs getOffsetPageCount={getOffsetPageCount} offset={offset} perPage={perPage} count={count} pages={pages} setOffset={setOffset} />
      )}

      <GroupModal
        isOpen={groupModalOpen}
        setIsOpen={setGroupModalOpen}
        setRefresh={() => {}}
        submitText="Save"
        cancelText="Cancel"
        title={editGroupStatus ? "Edit Group" : "Add Group"}
        addGroupStatus={addGroupStatus}
        setAddGroupStatus={setAddGroupStatus}
        editGroupStatus={editGroupStatus}
        setEditGroupStatus={setEditGroupStatus}
        editId={editId}
        setEditId={setEditId}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    groups: Object.values(state.groups),
  };
};

export default connect(mapStateToProps, { getGroupDataCount, deleteGroupData, getDashboardData, getOperatorData })(GroupSection);
