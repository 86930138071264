import moment from "moment";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearUserDetails, manageUserStatus } from "src/actions/user";
import QuickMenu from "src/components/Shared/QuickMenu";
import { noProfile, baseUrl } from "src/config/host";

import { classNames } from "src/helpers/classNames";

const UserEntry = ({ user, setSelected = () => {}, clearUserDetails, manageUserStatus, getDashboardData, setDeleteId, userAuth, site, type, ...props }) => {
  const navigate = useNavigate();

  const formatMemberDate = (date) => {
    if (!date) return "";
    return moment(new Date(date)).format("MM/DD/YYYY");
  };

  return (
    <tr key={user?.email}>
      <td className="whitespace-nowrap py-3 pl-4 pr-3 text-sm sm:pl-6">
        <div className="flex items-center">
          <div className="rounded-full hidden sm:block h-10 w-10 flex-shrink-0 overflow-hidden">
            {user?.image ? (
              <img className="object-cover h-full w-full" src={baseUrl + user?.image} alt="profile" onError={(e) => (e.target.src = `${noProfile}`)} />
            ) : (
              <img className="object-cover h-full w-full" src={noProfile} alt="profile" onError={(e) => (e.target.src = `${noProfile}`)} />
            )}
          </div>
          <div className="sm:ml-4">
            <div className="font-medium text-gray-900 flex items-center gap-x-2">
              {user?.name}
              {user?.default_user && <span className="bg-gray-100 text-gray-400 rounded-full px-2 py-[1px]">Super Admin</span>}
            </div>

            <div className="text-gray-500">
              {user.insert_type === "manual" ? "Manually" : user?.insert_type === "sso" ? "SSO" : "API"} created {formatMemberDate(user.created_at)}
            </div>
          </div>
        </div>
      </td>
      <td className="hidden sm:table-cell whitespace-nowrap px-3 py-3 text-sm text-gray-500">
        <div className="text-gray-900">{user?.email}</div>
        <div className="text-gray-500">{user?.username}</div>
      </td>
      <td className="hidden lg:flex max-h-[73.5px] px-3 py-4 text-sm text-gray-500">
        {(user?.permissions && user.permissions.length > 0) || (user?.group_permissions && user.group_permissions > 0) ? (
          <p
            onClick={() => {
              setSelected({ user, site });
            }}
            className="hover:underline cursor-pointer bg-gray-50 rounded-md m-0 px-[7px] py-[3px] flex items-center"
          >
            {user.permissions.length + (user?.group_permissions && user.group_permissions > 0 ? user.group_permissions : 0)} permission
            {user.permissions.length + (user?.group_permissions && user.group_permissions > 0 ? user.group_permissions : 0) > 1 ? "s" : ""}
          </p>
        ) : (
          <p className="cursor-default bg-gray-50 rounded-md m-0 px-[7px] py-[3px] flex items-center">None</p>
        )}
      </td>
      <td className="hidden lg:table-cell whitespace-nowrap px-3 py-3 text-sm text-gray-500">
        <button
          onClick={() => manageUserStatus(user)}
          className={classNames(
            "flex justify-center w-[52px] rounded-full px-2 text-xs font-semibold leading-5",
            user.active_status ? "bg-green-200 text-green-700 opacity-50" : "bg-gray-50 text-gray-500"
          )}
        >
          {user.active_status ? "Active" : "Inactive"}
        </button>
      </td>
      <td className="relative whitespace-nowrap flex justify-end py-3 pl-3 pr-4 text-sm font-medium sm:pr-6">
        <QuickMenu
          items={[
            {
              name: "Edit permissions",
              hideWhen: site.api_state === "domo",
              onClick: () => {
                clearUserDetails(null);
                navigate(type === "admin" ? `/update-admin/${user._id}?tab=dashboards` : `/update-user/${user._id}?tab=dashboards`);
              },
            },
            {
              name: "User details",
              hideWhen: site.api_state === "domo",
              onClick: () => {
                clearUserDetails(null);
                navigate(type === "admin" ? `/update-admin/${user._id}?tab=details` : `/update-user/${user._id}?tab=details`);
              },
            },
            {
              name: "Delete",
              hideWhen: user.default_user || site.api_state === "domo",
              onClick: () => {
                setDeleteId(user._id);
              },
              color: "text-red-500",
            },
            {
              name: "View",
              hideWhen: site.api_state !== "domo",
              onClick: () => {
                clearUserDetails(null);
                navigate(type === "admin" ? `/update-admin/${user._id}?tab=details` : `/update-user/${user._id}?tab=details`);
              },
            },
          ]}
        />
      </td>
    </tr>
  );
};

const mapStateToProps = (state) => {
  return {
    userAuth: state.auth.user,
    site: state.site,
  };
};

export default connect(mapStateToProps, { clearUserDetails, manageUserStatus })(UserEntry);
