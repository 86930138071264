import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { getDashboardData, manageDashboardData, orderingDashboardData, removeDashboard, setDashboardData } from "src/actions/dashboard";
import { getCredData } from "src/actions/dashboard";
import { fetchData } from "src/async/fetch";
import Button from "src/components/Shared/Button";
import DefaultModal from "src/components/Shared/DefaultModal";
import FrontLabeledInput from "src/components/Shared/Forms/FrontLabeledInput";
import PageHeader from "src/components/Shared/PageHeader";
import { hostUrl } from "src/config/host";
import { validate as uuidValidate } from "uuid";
import Input from "../Shared/Forms/Input";
import SelectMenu from "../Shared/SelectMenu";
import DashboardEntry from "./DashboardEntry";
import DashboardList from "./DashboardList";
import { PlusIcon } from "@heroicons/react/outline";
import TextArea from "../Shared/TextArea";

const DashboardSection = ({ dashboards, user, getDashboardData, manageDashboardData, removeDashboard, setDashboardData, orderingDashboardData, site, getCredData }) => {
  const [loaded, setLoaded] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [createModal, setCreateModal] = useState(false);

  const [newDashboard, setNewDashboard] = useState({
    name: "",
    dashboard_type: "embed",
    credential_id: null,
    embed_id: "",
    custom_html: "",
  });

  const [dashboardCredentials, setDashboardCredentials] = useState([]);
  const getDashboardCredentials = async () => {
    const ac = new AbortController();

    try {
      const res = await fetchData("POST", `${hostUrl}/dashboard/credential/get`, {}, ac.signal);
      const data = await res.json();

      if (data.status === 200) {
        let creds = data.data.map((cred) => {
          return { value: cred._id, key: cred.name };
        });
        setDashboardCredentials(creds);
      } else {
        throw new Error(data.message);
      }
    } catch (err) {
      throw new Error(err.message);
    }
    return () => ac.abort();
  };

  useEffect(() => {
    getDashboardCredentials();
  }, []);

  useEffect(() => {
    const ac = new AbortController();

    const loadSiteCred = async () => {
      try {
        await getCredData({}, ac.signal);
      } catch (err) {
        console.dir(err.message);
      }
    };

    const loadDashboardData = async () => {
      try {
        await getDashboardData({}, ac.signal);
        setLoaded(true);
      } catch (err) {
        setLoaded(true);
        console.dir(err.message);
      }
    };

    loadSiteCred();
    loadDashboardData();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createNewDashboard = async () => {
    try {
      let dashboard = {
        credential_id: newDashboard.credential_id,
        dashboard_type: newDashboard.dashboard_type,
        custom_html: newDashboard.custom_html,
        created_at: "",
        created_by: "",
        deleted_at: null,
        deleted_by: null,
        embed_id: newDashboard.embed_id,
        is_default: false,
        name: newDashboard.name,
        updated_at: "",
        updated_by: user._id,
        _id: null,
      };

      const { message } = await manageDashboardData({
        dashboards: [dashboard],
      });
      setCreateModal(false);
      toast.success(message);
      setNewDashboard({
        name: "",
        dashboard_type: "embed",
        credential_id: null,
        embed_id: "",
        custom_html: "",
      });
    } catch (err) {
      toast.error(err.message);
    }
  };

  const updateDefaultKey = async (dashboardId) => {
    let updatedDashboards = dashboards.map((dashboard) => {
      if (dashboard._id === dashboardId) {
        if (dashboard.is_default) {
          return { ...dashboard, is_default: false };
        } else {
          return { ...dashboard, is_default: true };
        }
        // return { ...dashboard, is_default: true };
      } else {
        return { ...dashboard, is_default: false };
      }
    });
    try {
      const { message } = await manageDashboardData({
        dashboards: updatedDashboards,
      });

      await getDashboardData({});
      toast.success(message);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const deleteDashboard = async (id) => {
    const ac = new AbortController();
    if (id && !uuidValidate(id)) {
      try {
        await removeDashboard(id);
        await getDashboardData({}, ac.signal);
      } catch (err) {
        console.dir(err);
      }
    }
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(dashboards, result.source.index, result.destination.index);

    setDashboardData(items);
    try {
      await orderingDashboardData({ dashboards: items });
    } catch (err) {
      console.dir(err);
    }
  };

  return (
    <>
      <PageHeader>Dashboards</PageHeader>
      {site.api_state !== "domo" && (
        <div className="h-full w-full flex items-center justify-end">
          <Button styleType="secondary" onClick={() => setCreateModal(true)}>
            Add Dashboard
          </Button>
        </div>
      )}
      <DefaultModal
        isOpen={createModal}
        closeModal={() => setCreateModal(false)}
        onSubmit={() => {
          //setCreateModal(false);
          createNewDashboard();
        }}
        submitText="Submit"
        cancelText="Cancel"
        title="Create a Dashboard"
      >
        <div className="whitespace-nowrap text-sm text-gray-500 grid gap-y-8 pl-2">
          <div className="grid gap-y-3 mb-6 max-w-[400px]">
            <div className="grid space-y-2 my-5">
              <Input type="text" name="dashboard-name" label="Name" value={newDashboard.name} onChange={(e) => setNewDashboard({ ...newDashboard, name: e.target.value })} />
            </div>
            <div className="relative">
              <button
                onClick={() => {
                  newDashboard.dashboard_type === "custom" ? setNewDashboard({ ...newDashboard, dashboard_type: "embed" }) : setNewDashboard({ ...newDashboard, dashboard_type: "custom" });
                }}
                type="button"
                className="hover:underline absolute top-[1px] right-0 flex items-center text-gray-400 text-sm font-medium cursor-pointer z-10"
              >
                {newDashboard.dashboard_type === "custom" ? (
                  <>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" className="w-4 h-4 mr-1">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"></path>
                    </svg>
                    <span>Use DOMO embed_id</span>
                  </>
                ) : (
                  <>
                    <PlusIcon className="w-4 h-4 mr-1" />
                    <span>Insert custom HTML</span>
                  </>
                )}
              </button>
              {newDashboard.dashboard_type === "custom" ? (
                <TextArea
                  label="Custom HTML"
                  value={newDashboard.custom_html}
                  onChange={(e) => setNewDashboard({ ...newDashboard, custom_html: e.target.value })}
                  placeholder="<iframe> ... </iframe>"
                  textType="code"
                />
              ) : (
                <div className="grid gap-y-3">
                  <SelectMenu
                    label="DOMO credentials"
                    startIndex={-1}
                    options={dashboardCredentials}
                    setOption={(option) => {
                      setNewDashboard({ ...newDashboard, credential_id: option.value });
                    }}
                  />
                  <FrontLabeledInput label="Embed ID" type="text" value={newDashboard.embed_id} onChange={(e) => setNewDashboard({ ...newDashboard, embed_id: e.target.value })} />
                </div>
              )}
            </div>
          </div>
        </div>
      </DefaultModal>
      <DefaultModal isOpen={deleteModal} closeModal={() => setDeleteModal(false)} onSubmit={() => {}} submitText="Delete" cancelText="Cancel" title="Delete dashboard">
        <div className="whitespace-nowrap text-sm text-gray-500 grid gap-y-8">Are you sure you want to delete this dashboard?</div>
      </DefaultModal>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              <DashboardList loaded={loaded} dataExists={dashboards.length > 0}>
                {dashboards.length > 0 &&
                  dashboards.map((dashboard, index) => (
                    <Draggable key={dashboard._id} draggableId={`${dashboard._id}`} index={index}>
                      {(provided, snapshot) => (
                        <DashboardEntry
                          placeholder={provided.placeholder}
                          innerRef={provided.innerRef}
                          draggableProps={provided.draggableProps}
                          dragHandleProps={provided.dragHandleProps}
                          key={dashboard._id}
                          dashboard={dashboard}
                          isDefault={dashboard.is_default}
                          updateDefaultKey={updateDefaultKey}
                          deleteDashboard={deleteDashboard}
                        />
                      )}
                    </Draggable>
                  ))}
              </DashboardList>
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    dashboards: state.dashboards,
    site: state.site,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, {
  getDashboardData,
  manageDashboardData,
  removeDashboard,
  setDashboardData,
  orderingDashboardData,
  getCredData,
})(DashboardSection);
