import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { deleteActivityData, getActivity } from "src/actions/activity";
import Search from "./Search";
import ActivityList from "./ActivityList";
import { getUsers, getGroupData } from "src/actions/user";
import { getDashboardData } from "src/actions/dashboard";
import PageHeader from "src/components/Shared/PageHeader";
import ActivityEntry from "./ActivityEntry";
import DefaultModal from "src/components/Shared/DefaultModal";
import { toast } from "react-toastify";
// eslint-disable-next-line no-unused-vars

function ActivitySection({ site, getUsers, deleteActivityData, activity, users, getGroupData, groups, getDashboardData, dashboards, getActivity, ...props }) {
  const [perPage, setPage] = useState(10);
  const [start_date, setStateDate] = useState("");
  const [end_date, setEndDate] = useState("");

  const [user, setUser] = useState("");
  const [group, setGroup] = useState("");
  const [dashboard, setDashboard] = useState("");
  const [activityData, setActivityData] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const [deleteId, setDeleteId] = useState(null);

  useEffect(() => {
    const ac = new AbortController();
    const loadData = async () => {
      setLoaded(false);
      try {
        const data = await getActivity({ limit: perPage, user_id: user, group_id: group, dashboard_id: dashboard, start_date: start_date, end_date: end_date }, ac.signal);
        setActivityData(data);
      } catch (err) {
        console.dir(err.message);
      }
    };

    loadData();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [perPage, start_date, end_date, group, user, dashboard, activity.length]);

  useEffect(() => {
    setLoaded(true);
  }, [activityData]);

  useEffect(() => {
    const ac = new AbortController();
    setLoaded(false);
    const loadDashboards = async () => {
      try {
        setLoaded(true);
        await getDashboardData({}, ac.signal);
      } catch (err) {
        setLoaded(true);
        console.dir(err.message);
      }
    };

    loadDashboards();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const ac = new AbortController();
    setLoaded(false);
    const loadGroups = async () => {
      try {
        setLoaded(true);
        await getGroupData({});
      } catch (err) {
        setLoaded(false);
        console.dir(err.message);
      }
    };

    loadGroups();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeDel = async (e) => {
    e.preventDefault();

    try {
      const message = await deleteActivityData(deleteId);
      setDeleteId(null);
      toast.success(message);
    } catch (err) {
      toast.error(err.message);
    }
  };

  useEffect(() => {
    const ac = new AbortController();

    const loadUsers = async () => {
      try {
        const userCount = await getUsers({}, ac.signal);

        // eslint-disable-next-line no-unused-vars
        const availablePages = Math.ceil(userCount / +perPage);
      } catch (err) {
        console.dir(err.message);
      }
    };

    loadUsers();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageHeader>Activity List</PageHeader>

      <DefaultModal isOpen={!!deleteId} closeModal={() => setDeleteId(null)} onSubmit={removeDel} submitText="Delete" cancelText="Cancel" title="Delete item">
        <div className="whitespace-nowrap text-sm text-gray-500 grid gap-y-8">Are you sure you want to delete?</div>
      </DefaultModal>
      <Search
        setStateDate={setStateDate}
        setEndDate={setEndDate}
        count={activityData.length}
        users={users}
        groups={groups}
        dashboards={dashboards}
        setPage={setPage}
        setUser={setUser}
        user={user}
        setGroup={setGroup}
        group={group}
        setDashboard={setDashboard}
        dashboard={dashboard}
      />
      <ActivityList setDeleteId={setDeleteId} deleteId={deleteId} loaded={loaded} dataExists={activityData.length > 0}>
        {activityData.length > 0 && activityData.map((item) => <ActivityEntry key={item._id} activity={item} deleteId={deleteId} setDeleteId={setDeleteId} />)}
      </ActivityList>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    site: state.site,
    groups: Object.values(state.groups),
    dashboards: Object.values(state.dashboards),
    users: Object.values(state.users),
    activity: Object.values(state.activity),
  };
};
export default connect(mapStateToProps, { getActivity, getUsers, deleteActivityData, getGroupData, getDashboardData })(ActivitySection);
