import React from "react";
import NoData from "src/components/Shared/NoData";
import Preloader from "src/components/Shared/Preloader";

const SSOList = ({ ...props }) => {
  return (
    <div className="w-full flex flex-col">
      <div className="w-full mt-3">
        <div className="shadow-md">
          <table className="w-full divide-y divide-gray-300 max-w-full">
            <thead className="hidden sm:table-header-group">
              <tr className="hidden sm:table-row">
                <th scope="col" className="bg-gray-50 hidden sm:table-cell py-3.5 pl-3 pr-3 text-left text-sm font-semibold text-gray-900 leading-none first:rounded-tl-lg">
                  Identity Provider
                </th>
                <th scope="col" className="bg-gray-50 hidden sm:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900 leading-none">
                  IDP Entity ID OR Issuer
                </th>
                <th scope="col" className="bg-gray-50 hidden sm:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900 leading-none">
                  SAML login URL
                </th>
                <th scope="col" className="bg-gray-50 hidden sm:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900 leading-none">
                  Status
                </th>
                <th scope="col" className="bg-gray-50 relative py-3.5 pl-3 pr-3 leading-none last:rounded-tr-lg">
                  <span className="sr-only">Edit</span>
                </th>
              </tr>
            </thead>
            <tbody className="block sm:table-row-group w-full divide-y divide-gray-200 bg-white">
              {props.loaded && props.children}
              {!props.loaded ? (
                <tr>
                  <td colSpan="5">
                    <Preloader />
                  </td>
                </tr>
              ) : null}
              {props.loaded && props.ssoExist === 0 && (
                <tr>
                  <td colSpan="5">
                    <NoData />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SSOList;
