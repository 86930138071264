import { SelectorIcon } from "@heroicons/react/outline";
import { XIcon } from "@heroicons/react/solid";
import { useRef, useState } from "react";
import { classNames } from "src/helpers/classNames";
import useOnClickOutside from "src/hooks/useClickOutside";

const MultiSelect = ({ options = [], title, error = false, selectedOptions = [], setSelectedOptions = () => {} }) => {
  // const [selectedOptions, setSelectedOptions] = useState([]);
  const [panelOpen, setPanelOpen] = useState(false);

  const ref = useRef();

  useOnClickOutside(ref, () => setPanelOpen(false));

  const updateSelected = (option) => {
    let updatedOptions;
    if (selectedOptions.find((selectedOption) => selectedOption === option)) {
      updatedOptions = selectedOptions.filter((selectedOption) => selectedOption !== option);
    } else {
      updatedOptions = [...selectedOptions, option];
    }
    setSelectedOptions(updatedOptions);
  };



  return (
    <div className="relative" ref={ref}>
      <div className={classNames("flex justify-between text-sm font-medium text-gray-700 mb-1", error ? "text-red-600" : "")}>
        {title} <div className="pl-2">{error ? "*Required" : ""}</div>
      </div>
      <div className="relative w-full flex items-center bg-white border border-gray-300 rounded-md shadow-sm pl-2 py-2 text-left cursor-default focus:ring-0 focus:border-highlightColor sm:text-sm">
        <div className="w-full flex justify-between" onClick={() => setPanelOpen(!panelOpen)}>
          <div className="flex gap-2 flex-wrap">
            {selectedOptions.length
              ? options
                  .filter((option) => selectedOptions.includes(option._id || option.id))
                  .map((selectedOption) => (
                    <div className="flex items-center rounded-full px-[2px] pl-[8px] py-[2px] bg-highlightColor text-white text-xs h-6" key={(selectedOption._id || selectedOption.id) + "selected"}>
                      {selectedOption.name}
                      <div onClick={() => updateSelected(selectedOption._id || selectedOption.id)} className="rounded-full p-1 ml-1">
                        <XIcon className="h-4 w-4 text-white" />
                      </div>
                    </div>
                  ))
              : <div className='h-6 flex items-center'>None selected</div>}
          </div>
          <span className="inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </span>
        </div>
      </div>
      <div className={classNames("bg-white mt-1 py-2 max-h-48 overflow-auto min-w-full shadow z-30 absolute top-[60px] rounded-md", panelOpen ? "flex flex-col" : "hidden")}>
        {options.map((option) => {
          return (
            <div
              key={option._id || option.id}
              className="cursor-pointer w-full flex items-center px-4 py-2 gap-x-3 border-white hover:bg-gray-100"
              onClick={() => {
                updateSelected(option._id);
              }}
            >
              <input type="checkbox" checked={selectedOptions.includes(option._id || option.id)} className="cursor-pointer rounded-sm text-highlightColor focus:ring-highlightColor" readOnly />
              <div className="">{option.name}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MultiSelect;
