import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getDashboardData } from "src/actions/dashboard";
import { getOperatorData } from "src/actions/operator";
import { addUser, getGroupData } from "src/actions/user";
import Button from "src/components/Shared/Button";
import PageHeader from "src/components/Shared/PageHeader";
import AddPermissionForm from "src/components/Users/Add/AddPermissionForm";
import AddUserForm from "src/components/Users/Add/AddUserForm";
import GroupModal from "src/components/Users/GroupModal";
import FormContainer from "src/components/Shared/FormContainer";
import { operatorNameConverter } from "src/helpers/operatorNameConverter";

const ManageUserSection = ({ dashboards, operators, groups, addUser, site, ...props }) => {
  const [formCount, setFormCount] = useState([0]);
  const [permissionFormCount, setPermissionFormCount] = useState([0]);
  const [userState, setUserState] = useState({});
  const [permissionState, setPermissionState] = useState({});
  const prevPermissionState = usePrevious(permissionState);
  const [isRefresh, setRefresh] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [addGroupStatus, setAddGroupStatus] = useState(false);
  const [editGroupStatus, setEditGroupStatus] = useState(false);

  const navigate = useNavigate();

  const queryParams = new URLSearchParams(window.location.search);
  const isAdmin = queryParams.get("isAdmin");

  useEffect(() => {
    if (prevPermissionState) {
      const prevPermissionStateNew = Object.values(prevPermissionState);
      const permissionStateNew = Object.values(permissionState);
      const getTrueValue = permissionStateNew.filter((item) => item.is_default);
      if (getTrueValue.length > 1) {
        let index = prevPermissionStateNew.findIndex((item) => item.is_default);
        if (index !== -1) {
          if (permissionStateNew[index]) {
            permissionStateNew[index].is_default = false;
            const finalPermissions = {};
            Object.keys(permissionStateNew).forEach((count) => {
              finalPermissions[count] = permissionStateNew[count];
            });
            setPermissionState(finalPermissions);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissionState]);

  // Load dashboards
  useEffect(() => {
    const ac = new AbortController();

    const loadDashboards = async () => {
      try {
        await props.getDashboardData({}, ac.signal);
      } catch (err) {
        console.dir(err.message);
      }
    };

    loadDashboards();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Load operators
  useEffect(() => {
    const ac = new AbortController();

    const loadOperators = async () => {
      try {
        await props.getOperatorData({}, ac.signal);
      } catch (err) {
        // console.dir(err.message);
      }
    };

    loadOperators();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Load groups
  useEffect(() => {
    const ac = new AbortController();

    const loadGroups = async () => {
      try {
        await props.getGroupData({}, ac.signal);
      } catch (err) {
        console.dir(err.message);
      }
    };

    loadGroups();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefresh]);

  const addNewForm = (e) => {
    e.preventDefault();
    setFormCount([...formCount, formCount.slice(-1)[0] + 1]);
  };

  const addNewPermission = (e) => {
    e.preventDefault();
    setPermissionFormCount([...permissionFormCount, permissionFormCount.slice(-1)[0] + 1]);
  };

  const onSubmit = async () => {
    try {
      let userData = [];

      Object.values(userState).forEach((user) => {
        userData.push({ ...user, type: isAdmin ? "admin" : "user" });
      });

      await addUser({
        users: userData,
        permissions: Object.values(permissionState),
      });

      if (isAdmin) {
        return navigate("/admins");
      } else {
        return navigate("/users");
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  function usePrevious(value) {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const ref = useRef();
    // Store current value in ref
    useEffect(() => {
      ref.current = value;
    }, [value]); // Only re-run if value changes
    // Return previous value (happens before update in useEffect above)
    return ref.current;
  }

  return (
    <>
      <PageHeader>{isAdmin ? "Admins" : "Users"}</PageHeader>

      <h3 className="text-lg font-semibold text-gray-500">Create {isAdmin ? "admins" : "users"}</h3>
      <div className="grid gap-y-2">
        {formCount.map((count, i) => {
          return <AddUserForm key={i} isAdmin={isAdmin} index={count} formCount={formCount} setFormCount={setFormCount} state={userState} setState={setUserState} groups={groups} />;
        })}
      </div>
      <div className="mt-3 w-full flex justify-end space-x-3">
        <Button
          styleType="gray"
          type="button"
          onClick={() => {
            setModalOpen(!modalOpen);
            setAddGroupStatus(true);
          }}
        >
          Add group
        </Button>
        <Button styleType="secondary" onClick={addNewForm}>
          Add {isAdmin ? "admin" : "user"}
        </Button>
      </div>
      <div className="mt-4">
        <h3 className="text-lg font-semibold text-gray-500 pl-1 pb-1">Assign permissions</h3>
        <FormContainer>
          {isAdmin && (
            <div className="w-full sm:w-1/2 px-2 relative flex items-center">
              <div className="flex cursor-not-allowed">
                <div className="pt-1 flex h-5">
                  <input
                    checked={true}
                    disabled
                    id="notifications"
                    aria-describedby="notifications-description"
                    name="comments"
                    type="checkbox"
                    className="h-4 w-4 text-gray-300 border-gray-400 rounded"
                  />
                </div>
                <div className="ml-3 mb-2 text-sm">
                  <label htmlFor="notifications" className="mb-0 font-medium text-gray-700">
                    Is an admin
                  </label>
                  <p id="notifications-description" className="text-gray-500">
                    You are creating new admin(s)
                  </p>
                </div>
              </div>
            </div>
          )}
          <div className="w-full grid gap-y-0">
            {permissionFormCount.length > 0 ? (
              permissionFormCount.map((count, i) => {
                return (
                  <AddPermissionForm
                    key={i}
                    index={count}
                    dashboards={dashboards}
                    operators={operators?.map((operator) => {
                      return { ...operator, name: operatorNameConverter(operator.name), id: operator._id };
                    })}
                    formCount={permissionFormCount}
                    setFormCount={setPermissionFormCount}
                    state={permissionState}
                    setState={setPermissionState}
                  />
                );
              })
            ) : (
              <span className="text-gray-400">No permissions</span>
            )}
          </div>
        </FormContainer>
        <div className="mt-3 w-full flex justify-end">
          <Button styleType="secondary" onClick={addNewPermission}>
            Add permission
          </Button>
        </div>
      </div>
      <GroupModal
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        setRefresh={setRefresh}
        submitText="Save"
        cancelText="Cancel"
        title="Add Group"
        addGroupStatus={addGroupStatus}
        setAddGroupStatus={setAddGroupStatus}
        editGroupStatus={editGroupStatus}
        setEditGroupStatus={setEditGroupStatus}
      />
      <div className="w-full flex justify-end border-t-[1px] border-t-gray-200 mt-5 pt-3 gap-x-3">
        <Button styleType="gray" type="button" onClick={() => {}}>
          Clear all
        </Button>
        <Button type="button" onClick={onSubmit}>
          Submit
        </Button>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    dashboards: state.dashboards,
    operators: Object.values(state.operators),
    groups: Object.values(state.groups),
    site: state.site,
  };
};

export default connect(mapStateToProps, { getDashboardData, getOperatorData, getGroupData, addUser })(ManageUserSection);
