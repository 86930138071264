import { hostUrl } from "src/config/host";
import { fetchData } from "src/async/fetch";
import { GET_MENU_DATA } from "./type";

export const getMenuData =
  (body, signal = null) =>
  async (dispatch) => {
    try {
      const res = await fetchData("POST", `${hostUrl}/menu/list`, body, signal);
      const resData = await res.json();

      if (resData.status === 200) {
        dispatch({ type: GET_MENU_DATA, payload: resData.data });
      } else {
        throw new Error(resData.message);
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
