import { toast } from "react-toastify";
import Button from "src/components/Shared/Button";
import { noProfile } from "src/config/host";
import { convertToBase64 } from "src/helpers/image";
import { baseUrl } from "src/config/host";

const ProfileImage = ({ user, image, setImage, baseImage = noProfile }) => {
  const updateImage = (e) => {
    const allowedExts = ["jpg", "jpeg", "png", "bmp", "gif", "svg", "webp"];

    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];

      if (!allowedExts.includes(ext)) {
        toast.error("Please upload a valid image.");
      } else {
        convertToBase64(e.target.files[0], setImage);
      }
    }
  };

  return (
    <div className="grid gap-y-1">
      <label className="flex justify-between text-sm font-medium text-gray-700 mb-1">Photo</label>
      <div className="flex space-x-4 items-center">
        <div className="rounded-full hidden sm:block h-16 w-16 flex-shrink-0 overflow-hidden">
          {user?.image ? (
            <img className="object-cover h-full w-full" src={image.includes("data:image/") ? image : baseUrl + user?.image} alt="profile" onError={(e) => (e.target.src = `${noProfile}`)} />
          ) : (
            <img className="object-cover h-full w-full" src={baseImage} alt="profile" onError={(e) => (e.target.src = `${baseImage}`)} />
          )}
        </div>
        <div className="ml-6 flex items-center mt-0">
          <Button styleType="gray" className="overflow-hidden relative">
            <span className="text-sm text-gray-600">Upload image</span>
            <input type="file" className="image-upload-input absolute opacity-0 w-96 top-0 right-0 h-full cursor-pointer z-10" onChange={updateImage} />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ProfileImage;

// <div className="grid gap-y-2">

//   <div className="">Change Image</div>
//   <div>
//     <div className="rounded-full overflow-hidden">
//       <img className="h-20 w-20" src={image || user.image || noProfile} alt="Logo" onError={(e) => (e.target.src = `${noProfile}`)} />
//     </div>

//     <div className="image-upload">
//       <input type="file" className="image-upload-input" onChange={updateImage} />
//     </div>
//   </div>
// </div>
