import React from "react";
import NoData from "src/components/Shared/NoData";
import Preloader from "src/components/Shared/Preloader";
import Table from "src/components/Shared/Table/Table";
import TableBody from "src/components/Shared/Table/TableBody";
import TableHeader from "src/components/Shared/Table/TableHeader";

const ActivityList = ({ ...props }) => {
  return (
    <div className="flex flex-col">
      <Table>
        <TableHeader>
          <tr>
            <th scope="col" className="bg-gray-50 hidden sm:table-cell py-3.5 pl-3 pr-3 text-left text-sm font-semibold text-gray-900 leading-none first:rounded-tl-lg">
              Username
            </th>
            <th scope="col" className="bg-gray-50 sm:hidden py-3.5 pl-3 pr-3 text-left text-sm font-semibold text-gray-900 leading-none first:rounded-tl-lg">
              Username/Email
            </th>
            <th scope="col" className="bg-gray-50 hidden sm:table-cell py-3.5 pl-3 pr-3 text-left text-sm font-semibold text-gray-900 leading-none first:rounded-tl-lg">
              Email
            </th>
            <th scope="col" className="bg-gray-50 hidden md:table-cell py-3.5 pl-3 pr-3 text-left text-sm font-semibold text-gray-900 leading-none first:rounded-tl-lg">
              Activity Type
            </th>
            <th scope="col" className="bg-gray-50 py-3.5 pl-3 pr-3 text-left text-sm font-semibold text-gray-900 leading-none first:rounded-tl-lg">
              Dashboard Accessed
            </th>
            <th scope="col" className="bg-gray-50 hidden sm:table-cell py-3.5 pl-3 pr-3 text-left text-sm font-semibold text-gray-900 leading-none first:rounded-tl-lg">
              Date
            </th>
            <th scope="col" className="bg-gray-50 relative py-3.5 pl-3 pr-3 leading-none last:rounded-tr-lg">
              <span className="sr-only">Edit</span>
            </th>
          </tr>
        </TableHeader>
        <TableBody>
          {props.loaded && props.children}
          {!props.loaded ? (
            <tr>
              <td colSpan="6">
                <Preloader />
              </td>
            </tr>
          ) : null}
          {props.loaded && !props.dataExists && (
            <tr>
              <td colSpan="6">
                <NoData />
              </td>
            </tr>
          )}
        </TableBody>
      </Table>
    </div>
  );
};

export default ActivityList;
