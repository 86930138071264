import { DocumentTextIcon, PresentationChartBarIcon } from "@heroicons/react/outline";
import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { fetchData } from "src/async/fetch";
import Button from "src/components/Shared/Button";
import FormContainer from "src/components/Shared/FormContainer";
import PageHeader from "src/components/Shared/PageHeader";
import AddPermissionForm from "src/components/Users/Add/AddPermissionForm";
import { hostUrl } from "src/config/host";
import { classNames } from "src/helpers/classNames";
import { validate as uuidValidate } from "uuid";
import { getDashboardData } from "src/actions/dashboard";
import { getOperatorData } from "src/actions/operator";
import { getGroupData, getUserDetails, updateUser } from "src/actions/user";
import EditUserForm from "./EditUserForm";
import { operatorNameConverter } from "src/helpers/operatorNameConverter";
import MultiSelect from "src/components/Shared/Forms/MultiSelect";

const UserEdit = ({ groups, dashboards, operators, getDashboardData, getOperatorData, getUserDetails, getGroupData, site, ...props }) => {
  const [user, setUser] = useState({});
  const [permissionFormCount, setPermissionFormCount] = useState([0]);
  const [permissionState, setPermissionState] = useState({});
  const prevPermissionState = usePrevious(permissionState);
  const [isAdmin, setIsAdmin] = useState();
  const { id } = useParams();

  function useQuery() {
    return new URLSearchParams(window.location.search);
  }
  const tab = useQuery().get("tab");

  const [selectedGroups, setSelectedGroups] = useState([]);

  useEffect(() => {
    if (user.groups !== undefined) {
      const groupIds = user.groups.map((item, index) => {
        return item._id;
      });
      setSelectedGroups(groupIds);
    }
  }, [user.groups]);

  const loadUsersAndDeps = async (ac = {}) => {
    try {
      let res = await fetchData("POST", `${hostUrl}/user/details`, { id }, ac.signal);
      res = await res.json();

      setUser({ ...res.data });
      const finalPermissions = {};
      const updatedStateCount = [];
      res.data.permissions.forEach((permission, i) => {
        finalPermissions[i] = permission;
        updatedStateCount.push(i);
      });
      setSelectedGroups(res.data.groups);
      setPermissionState(finalPermissions);
      setPermissionFormCount(updatedStateCount);
      await getDashboardData({}, ac.signal);
      await getOperatorData({}, ac.signal);
      await getGroupData({}, ac.signal);
    } catch (err) {
      console.dir(err.message);
    }
  };

  useEffect(() => {
    if (prevPermissionState) {
      const prevPermissionStateNew = Object.values(prevPermissionState);
      const permissionStateNew = Object.values(permissionState);
      const getTrueValue = permissionStateNew.filter((item) => item.is_default);
      if (getTrueValue.length > 1) {
        let index = prevPermissionStateNew.findIndex((item) => item.is_default);
        if (index !== -1) {
          if (permissionStateNew[index]) {
            permissionStateNew[index].is_default = false;
            const finalPermissions = {};
            Object.keys(permissionStateNew).forEach((count) => {
              finalPermissions[count] = permissionStateNew[count];
            });
            setPermissionState(finalPermissions);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissionState]);

  useEffect(() => {
    const ac = new AbortController();
    loadUsersAndDeps(ac);

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async () => {
    let currentPermissions = [];

    if (Object.values(permissionState).length) {
      Object.values(permissionState).forEach((permission) => {
        if (permission?._id && uuidValidate(permission._id)) {
          currentPermissions = [...currentPermissions, { ...permission, _id: null }];
        } else {
          currentPermissions = [...currentPermissions, { ...permission }];
        }
      });
    }

    const updateData = {
      ...user,
      groups: selectedGroups,
      permissions: currentPermissions,
    };

    try {
      const message = await props.updateUser(updateData);
      toast.success(message);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const addNewPermission = (e) => {
    e.preventDefault();
    if (permissionFormCount.length > 0) {
      setPermissionFormCount([...permissionFormCount, permissionFormCount.slice(-1)[0] + 1]);
    } else {
      setPermissionFormCount([0]);
    }
  };

  const handleSetUser = (value, key) => {
    setUser({ ...user, [key]: value });
  };

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);

    return ref.current;
  }

  useEffect(() => {
    if (user.type !== undefined) {
      if (user.type === "admin") {
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
      }
    }
  }, [user]);

  useEffect(() => {
    if (isAdmin !== undefined) {
      if (isAdmin === true) {
        setUser({ ...user, type: "admin" });
      } else {
        setUser({ ...user, type: "user" });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdmin]);
  const tabs = [
    { name: "Assigned Dashboards", icon: PresentationChartBarIcon },
    { name: "User Details", icon: DocumentTextIcon },
  ];
  const [selectedTab, setSelectedTab] = useState("Assigned Dashboards");

  useEffect(() => {
    if (tab === "dashboards") {
      setSelectedTab("Assigned Dashboards");
    } else {
      setSelectedTab("User Details");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <PageHeader>Edit {user.type === "admin" ? "Admin" : "User"}</PageHeader>
      <div className="border-b border-gray-200 overflow-auto sm:overflow-hidden">
        <nav className="-mb-px flex space-x-8" aria-label="Tabs">
          {tabs.map((tab, i) => (
            <div
              key={tab.name}
              onClick={() => {
                setSelectedTab(tab.name);
                loadUsersAndDeps();
              }}
              className={classNames(
                tab.name === selectedTab ? "border-highlightColor text-highlightColor" : "border-transparent text-gray-400 hover:text-gray-500 hover:border-gray-400",
                "group inline-flex items-center pb-3 px-1 border-b-2 font-medium text-sm cursor-pointer"
              )}
              aria-current={tab.name === selectedTab ? "page" : undefined}
            >
              <tab.icon className={classNames(tab.name === selectedTab ? "text-highlightColor" : "text-gray-400 group-hover:text-gray-400", "-ml-0.5 mr-2 h-5 w-5")} aria-hidden="true" />
              <span className="block whitespace-nowrap">{tab.name}</span>
            </div>
          ))}
        </nav>
      </div>
      <div className="mt-3">
        {tabs.find((tab) => tab.name === selectedTab).name === "User Details" && (
          <EditUserForm
            isAdmin={isAdmin}
            setIsAdmin={setIsAdmin}
            name={user.name}
            password={user.password}
            confirm_password={user.confirm_password}
            email={user.email}
            username={user.username}
            image={user.image}
            notifications={user.notifications}
            user={user}
            setUser={handleSetUser}
          />
        )}
        {tabs.find((tab) => tab.name === selectedTab).name === "Assigned Dashboards" && (
          <div className="grid gap-y-4">
            <FormContainer>
              {/* Groups */}
              <div className="sm:w-1/2 w-full px-2">
                <MultiSelect options={groups} title="Assign groups" selectedOptions={selectedGroups} setSelectedOptions={setSelectedGroups}></MultiSelect>
              </div>
            </FormContainer>
            <FormContainer>
              <div className="w-full grid gap-y-0 lg:mt-2">
                {permissionFormCount.length > 0 ? (
                  permissionFormCount.map((count, i) => {
                    return (
                      <AddPermissionForm
                        key={i}
                        index={count}
                        dashboards={dashboards}
                        operators={operators?.map((operator) => {
                          return { ...operator, name: operatorNameConverter(operator.name), id: operator._id };
                        })}
                        formCount={permissionFormCount}
                        setFormCount={setPermissionFormCount}
                        state={permissionState}
                        setState={setPermissionState}
                      />
                    );
                  })
                ) : (
                  <p className="m-0 inline text-md font-medium text-gray-400 ml-3">
                    <span className="px-3.5 py-3 rounded-md bg-gray-50">No permissions assigned</span>
                  </p>
                )}
              </div>
              <div className="md:mt-1 mb-1 md:mb-0 w-full flex justify-end lg:px-[18px]">
                <Button styleType="secondary" onClick={addNewPermission}>
                  Add permission
                </Button>
              </div>
            </FormContainer>
          </div>
        )}
      </div>
      {/* <GroupModal setRefresh={setRefresh} /> */}
      <div className="w-full flex justify-end mt-5 pt-3 gap-x-3">
        <Button styleType="gray" type="button" onClick={loadUsersAndDeps}>
          Clear all
        </Button>
        <Button type="button" onClick={onSubmit}>
          Save
        </Button>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    dashboards: Object.values(state.dashboards),
    operators: Object.values(state.operators),
    user: state.userDetails,
    groups: Object.values(state.groups),
    site: state.site,
  };
};

export default connect(mapStateToProps, { getUserDetails, updateUser, getGroupData, getDashboardData, getOperatorData })(UserEdit);
