import { XIcon } from "@heroicons/react/solid";
import { useEffect, useState } from "react";
import Input from "src/components/Shared/Forms/Input";
import SimpleDropdown from "src/components/Shared/Forms/SimpleDropdown";
import Toggle from "src/components/Shared/Toggle";

const AddPermissionForm = ({ dashboards, isDefault, updateDefaultKey, operators, state, setState, index, usesTrustedValue = false, ...props }) => {
  const [dashboardId, setDashboardId] = useState({ _id: -1, name: "None selected" });
  const [operatorId, setOperatorId] = useState({ _id: -1, name: "None selected" });

  useEffect(() => {
    let selectedOperator = operators.filter((operator) => operator._id === state[index]?.operator_id)[0];
    setOperatorId(selectedOperator);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operators]);

  useEffect(() => {
    let selectedDashboard = dashboards.filter((dashboard) => dashboard._id === state[index]?.dashboard_id)[0];
    setDashboardId(selectedDashboard);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboards]);

  const [columnName, setColumnName] = useState("");
  const [columnValue, setColumnValue] = useState("");
  const [trustedAttribute, setTrustedAttribute] = useState({ _id: -1, name: "None selected" });

  const removeForm = (e) => {
    e.preventDefault();
    delete state[index];
    setState(state);

    const availableForms = props.formCount.filter((count) => count !== index);
    props.setFormCount(availableForms);
  };

  const updateForm = (e, name, next) => {
    // eslint-disable-next-line eqeqeq
    next({ _id: e._id, name: e.name });
    if (e._id !== -1) {
      handleState(name, e._id || e._id);
    } else {
      handleState(name, "");
    }
  };

  const handleState = (name, value) => {
    setState({
      ...state,
      [index]: {
        ...state[index],
        [name]: value,
      },
    });
  };

  useEffect(() => {
    setState({
      ...state,
      [index]: {
        dashboard_id: state[index]?.dashboard_id,
        operator_id: state[index]?.operator_id,
        column_name: state[index]?.column_name,
        column_value: state[index]?.column_value,
        is_default: state[index]?.is_default,
      },
    });

    setDashboardId({});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state[index] && state[index].dashboard_id) {
      const dashboardIndex = dashboards.findIndex((item) => item._id === state[index].dashboard_id);
      if (dashboardIndex !== -1) {
        setDashboardId(dashboards[dashboardIndex]);
        updateForm(dashboards[dashboardIndex], "dashboard_id", setDashboardId);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state[index]?.dashboard_id]);

  useEffect(() => {
    if (state[index] && state[index].operator_id) {
      const operatorIndex = operators.findIndex((item) => item._id === state[index].operator_id);
      if (operatorIndex !== -1) {
        setOperatorId(operators[operatorIndex]);
        updateForm(operators[operatorIndex], "operator_id", setOperatorId);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state[index]?.operator_id]);

  useEffect(() => {
    if (state[index] && state[index].column_name) {
      setColumnName(state[index].column_name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state[index]?.column_name]);

  useEffect(() => {
    if (state[index] && state[index].column_value) {
      setColumnValue(state[index].column_value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state[index]?.column_value]);

  useEffect(() => {
    if (state[index] && state[index].trusted_attribute?.includes("user.")) {
      setTrustedAttribute({
        _id: state[index].trusted_attribute,
        value: state[index].trusted_attribute,
        name: state[index].trusted_attribute,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state[index]?.trusted_attribute]);

  useEffect(() => {
    setState({
      ...state,
      [index]: {
        ...state[index],
        is_default: state[index]?.is_default,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state[index]?.is_default]);

  const setCheckboxOpen = (e) => {
    setState({
      ...state,
      [index]: {
        ...state[index],
        is_default: e,
      },
    });
  };

  return (
    <>
      <div className="flex flex-col lg:flex-row w-full items-end relative gap-y-4 mb-6 border-[1px] border-gray-200 rounded-md px-3 py-4 lg:border-none lg:rounded-none lg:px-0 lg:py-0">
        <div className="hidden lg:flex flex-col w-full px-2 md:w-auto md:px-0 items-start md:justify-between lg:h-full mr-2">
          <p className="hidden sm:flex justify-between text-sm font-medium text-gray-700 mb-1">Default</p>
          <div className="inline-flex px-2 gap-x-2 items-center py-1.5 rounded-full bg-gray-50 md:mr-2">
            <Toggle value={state[index]?.is_default || false} setValue={setCheckboxOpen} />
          </div>
        </div>
        <div className="w-full flex flex-col lg:flex-row gap-y-4 lg:mr-[8px] gap-x-3">
          <div className="flex w-full md:w-2/3 lg:w-1/3 gap-x-5">
            {/* Dashboard */}
            <div className="w-full">
              <SimpleDropdown label={"Dashboard"} options={dashboards} selected={dashboardId} setSelected={(e) => updateForm(e, "dashboard_id", setDashboardId)} />
            </div>
            <div className="lg:hidden flex flex-col justify-end lg:w-0 px-2 md:w-auto md:px-0 items-start md:justify-between lg:h-full mr-2">
              <p className="hidden sm:flex justify-between text-sm font-medium text-gray-700 mb-1">Default</p>
              <div className="inline-flex px-2 gap-x-2 items-center py-1.5 rounded-full bg-gray-50 md:mr-2">
                <Toggle value={state[index]?.is_default || false} setValue={setCheckboxOpen} />
              </div>
            </div>
          </div>
          <div className="w-full flex gap-x-3 mr-3">
            {/* Column name */}
            <div className="grow">
              <Input
                name="column-name"
                label={"Column name"}
                value={columnName}
                onChange={(e) => {
                  handleState("column_name", e.target.value);
                  setColumnName(e.target.value);
                }}
              />
            </div>
            {/* Operator */}
            <div className="w-[110px]">
              <SimpleDropdown label={"Operator"} options={operators} selected={operatorId} setSelected={(e) => updateForm(e, "operator_id", setOperatorId)} />
            </div>
            {/* Column value */}
            {usesTrustedValue ? (
              <div className="grow">
                <SimpleDropdown
                  label="Trusted attribute"
                  options={[
                    {
                      _id: "user.firstName",
                      value: "user.firstName",
                      name: "user.firstName",
                    },
                    {
                      _id: "user.lastName",
                      value: "user.lastName",
                      name: "user.lastName",
                    },
                    {
                      _id: "user.email",
                      value: "user.email",
                      name: "user.email",
                    },
                    {
                      _id: "user.username",
                      value: "user.username",
                      name: "user.username",
                    },
                    {
                      _id: "user.company",
                      value: "user.company",
                      name: "user.company",
                    },
                    {
                      _id: "user.department",
                      value: "user.department",
                      name: "user.department",
                    },
                  ]}
                  selected={trustedAttribute}
                  setSelected={(e) => updateForm(e, "trusted_attribute", setTrustedAttribute)}
                />
              </div>
            ) : (
              <div className="grow">
                <Input
                  name="column-value"
                  // label={index === 0 ? "Column value" : ""}
                  label={"Column value"}
                  value={columnValue}
                  onChange={(e) => {
                    handleState("column_value", e.target.value);
                    setColumnValue(e.target.value);
                  }}
                />
              </div>
            )}
          </div>
          <div className="h-[34px] w-[21px] absolute lg:bottom-0 top-1 lg:top-auto right-3 lg:-right-[12px]">
            <button type="button" className="h-[34px] bg-white rounded-md text-red-400 hover:text-red-500 focus:outline-none" onClick={removeForm}>
              <span className="sr-only">Close</span>
              <XIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddPermissionForm;
