import React, { useEffect, useRef, useState } from "react";
import "flatpickr/dist/themes/light.css";
import Flatpickr from "react-flatpickr";
import SelectMenu from "src/components/Shared/SelectMenu";
function Search({ users, groups, setPage, setGroup, setStateDate, setEndDate, dashboards, setDashboard, setUser, className = "", ...props }) {
  const [flatdate, setDate] = useState(null);

  const fp = useRef(null);
  const date = new Date();
  date.setHours(0, 0, 0, 0);
  const enddate = new Date();
  enddate.setHours(23);
  enddate.setMinutes(59);

  // eslint-disable-next-line no-unused-vars
  const [value, onChange] = useState([date.toISOString(), enddate.toISOString()]);

  useEffect(() => {
    setStateDate(value ? new Date(value[0]).toISOString() : "");
    setEndDate(value ? new Date(value[1]).toISOString() : "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (flatdate?.e && flatdate?.e[0] && flatdate?.e[1]) {
      let first = flatdate?.e[0] ? new Date(flatdate?.e[0]).toISOString() : "";
      let last = flatdate?.e[1] ? new Date(flatdate?.e[1]).toISOString() : "";

      setStateDate(first);
      setEndDate(last);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flatdate?.e[0] && flatdate?.e[1]]);

  return (
    <>
      <div className="flex justify-between items-center">
        <div className="w-full justify-between flex">
          <div className="flex gap-x-2 w-4/5">
            <div className="hidden md:flex gap-x-1">
              <SelectMenu
                options={[
                  { key: "10 Per Page", value: 10 },
                  { key: "20 Per Page", value: 25 },
                  { key: "50 Per Page", value: 50 },
                  { key: "75 Per Page", value: 75 },
                  { key: "100 Per Page", value: 100 },
                  { key: "All", value: 1000000000 },
                ]}
                setOption={(option) => setPage(option.value)}
              ></SelectMenu>

              <div className="w-full sm:w-[290px] xl:w-[420px] flex">
                <label htmlFor="search" className="sr-only">
                  Search
                </label>
                <div className="relative rounded-md shadow-sm w-full flex">
                  <Flatpickr
                    ref={fp}
                    className="w-full border-gray-300 text-sm rounded-md focus:ring-0 focus:border-highlightColor"
                    // id="datepicker-publish-input"
                    placeholder="Select a date"
                    onChange={(e) => {
                      setDate({ e });
                    }}
                    options={{
                      altInput: true,
                      altFormat: "F j, Y",
                      mode: "range",
                      dateFormat: "d.m.y",
                    }}
                  />
                </div>
              </div>

              <SelectMenu
                className={className}
                options={[
                  { key: "All Users", value: "" },
                  ...(users.length
                    ? users.map((user, index) => {
                        return {
                          key: user.name,
                          value: user._id,
                        };
                      })
                    : []),
                ]}
                setOption={(option) => setUser(option.value)}
              ></SelectMenu>
              <SelectMenu
                className={className}
                options={[
                  { key: "All Groups", value: "" },
                  ...(groups.length
                    ? groups.map((group, index) => {
                        return {
                          key: group.name,
                          value: group._id,
                        };
                      })
                    : []),
                ]}
                setOption={(option) => setGroup(option.value)}
              ></SelectMenu>

              <SelectMenu
                className={className}
                options={[
                  { key: "All Dashboards", value: "" },
                  ...(dashboards.length
                    ? dashboards.map((dashboard, index) => {
                        return {
                          key: dashboard.name,
                          value: dashboard._id,
                        };
                      })
                    : []),
                ]}
                setOption={(option) => setDashboard(option.value)}
              ></SelectMenu>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Search;
