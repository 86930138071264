import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { login } from "src/actions/auth";
import { getSSOData, ssoLoginData } from "src/actions/sso";
import Button from "src/components/Shared/Button";
import Input from "src/components/Shared/Forms/Input";
import Preloader from "src/components/Shared/Preloader";
import AuthContainer from "./AuthContainer";
import AuthHeader from "./AuthHeader";
import { useLocation } from "react-router-dom";

const LoginContent = ({ site, getSSOData, login, ssoLoginData, seoTitle }) => {
  const { pathname } = useLocation();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [ssodata, setSSOdata] = useState([]);
  const [ssoDataLoaded, setSSODataLoaded] = useState(false);

  const loginUser = async (e) => {
    setError("");

    e.preventDefault();

    try {
      const message = await login({
        email,
        password,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        token: localStorage.getItem("access_token"),
      });
      setError("");
      toast.success(message);
      return navigate("/dashboards");
    } catch (err) {
      setError(err.message);
    }
  };

  let access_token = new URLSearchParams(window.location.search).get("access_token");
  let refresh_token = new URLSearchParams(window.location.search).get("refresh_token");
  let user_id = new URLSearchParams(window.location.search).get("user_id");

  useEffect(() => {
    if (access_token && refresh_token && user_id) {
      localStorage.setItem("access_token", access_token);
      localStorage.setItem("refresh_token", refresh_token);
      localStorage.setItem("userId", user_id);
      navigate("/users");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const ac = new AbortController();
    const loadDefaultSSO = async () => {
      try {
        const data = await getSSOData({ default: true }, ac.signal);
        if (data.length === 0) {
          setSSODataLoaded(true);
          navigate("/login");
        } else {
          setSSOdata(data);
          if (data[0]?._id && pathname === "/" && site.sso_admin_redirect) {
            ssoLogin(data[0]?._id);
          } else {
            setSSODataLoaded(true);
          }
        }
      } catch (err) {
        console.dir(err);
      }
    };

    loadDefaultSSO();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ssoLogin = async (e) => {
    try {
      const message = await ssoLoginData({ id: e });
      window.location.href = message;
    } catch (error) {
      console.dir(error);
    }
  };

  return (
    <>
      {ssoDataLoaded ? (
        <>
          <AuthHeader
            seoTitle={seoTitle}
            title="Administrator login portal"
            switchTo={{
              startText: "Or switch to",
              text: "User Portal",
              location: site?.site_domain || "/",
            }}
          />
          <AuthContainer site={site}>
            <>
              {pathname === "/sso-logout" && ssodata.length > 0 && !user_id && (
                <>
                  <div className="-mt-5 mb-7 w-full flex justify-center">
                    <div className="px-3 py-2 rounded-md bg-red-100">You have been logged out.</div>
                  </div>
                  {ssodata.map((item) => (
                    <div key={item?._id} className="mt-4">
                      <Button
                        styleType="gray"
                        className="text-white border-0"
                        width="w-full"
                        style={{
                          color: site?.sso_button_text_color || "#fff",
                          backgroundColor: site?.sso_button_color,
                        }}
                        onClick={() => ssoLogin(item?._id)}
                      >
                        {site.sso_button_text ? site.sso_button_text : item.provider_name}
                      </Button>
                    </div>
                  ))}
                  <div className="my-5 flex items-center justify-between">
                    <div className="bg-gray-300 h-[1px] w-1/4"></div>
                    <span className="text-gray-500 text-xs sm:text-sm">Or continue with email</span>
                    <div className="bg-gray-300 h-[1px] w-1/4"></div>
                  </div>
                  <div className="">
                    <a
                      href="/login"
                      className="h-full inline-flex items-center justify-center rounded-md border px-4 py-2 text-regular font-medium shadow-sm focus:outline-none bg-white text-gray-600 cursor-pointer w-full"
                    >
                      <div className="flex items-center justif-center gap-x-2 text-sm">Go to sign in</div>
                    </a>
                  </div>
                </>
              )}
              {(pathname === "/" && ssoDataLoaded && ssodata.length === 0) || pathname === "/login" ? (
                <>
                  <form className="flex flex-col w-full gap-y-3" autoComplete="on">
                    {/* Email */}
                    <div className="w-full">
                      <Input
                        type="email"
                        name="contact-email"
                        label="Username/Email"
                        error={error === "Email field is required"}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            loginUser(e);
                          }
                        }}
                      />
                    </div>

                    {/* Password */}
                    <div className="w-full min-w-[200px]">
                      <Input
                        type="password"
                        name="password"
                        label="Password"
                        error={error === "Password field is required."}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Your Password"
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            loginUser(e);
                          }
                        }}
                      />
                    </div>
                  </form>
                  <Link to="/forgot-password" className="w-full flex justify-end mt-1 text-sm">
                    Forgot Password?
                  </Link>
                  <div className="mt-4">
                    <Button width="w-full" onClick={loginUser}>
                      Login
                    </Button>
                  </div>
                  {ssodata.length > 0 && (
                    <>
                      <div className="my-5 flex items-center justify-between">
                        <div className="bg-gray-300 h-[1px] w-1/4"></div>
                        <span className="text-gray-500 text-xs sm:text-sm">Or continue with SSO</span>
                        <div className="bg-gray-300 h-[1px] w-1/4"></div>
                      </div>
                      <div className="">
                        <a
                          href="/"
                          className="h-full inline-flex items-center justify-center rounded-md border px-4 py-2 text-regular font-medium shadow-sm focus:outline-none bg-white text-gray-600 cursor-pointer w-full"
                        >
                          <div className="flex items-center justif-center gap-x-2 text-sm">Go to SSO login</div>
                        </a>
                      </div>
                    </>
                  )}
                </>
              ) : (
                !(pathname === "/" || ssodata.length) && !ssoDataLoaded(<Preloader />)
              )}

              {/* <div className="form-action mt-2">
              <a
                // href="https://symlinktechllp.my.salesforce.com/?ec=302&startURL=%2Fidp%2Flogin%3Fapp%3D0sp5i000000blKb"
                href="https://trial-1942520.okta.com/app/trial-1942520_clearsquareportal_1/exk12nxefwuJRYD6m697/sso/saml"
                className="btn-action-primary mr-3"
              >
                OKTA SSO
              </a>

              <a
                href="https://symlinktechllp.my.salesforce.com/?ec=302&startURL=%2Fidp%2Flogin%3Fapp%3D0sp5i000000blKb"
                // href="https://trial-1942520.okta.com/app/trial-1942520_clearsquareportal_1/exk12nxefwuJRYD6m697/sso/saml"
                className="btn-action-secondary mr-3"
              >
                SALESFORCE SSO
              </a>
            </div> */}
            </>
          </AuthContainer>
        </>
      ) : (
        ""
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
    sso: state.sso,
  };
};

export default connect(mapStateToProps, { login, getSSOData, ssoLoginData })(LoginContent);
