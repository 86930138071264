import React, { useState } from "react";
import PageHeader from "src/components/Shared/PageHeader";
import { CogIcon, ShareIcon, TableIcon } from "@heroicons/react/outline";
import { classNames } from "src/helpers/classNames";
import SSOServiceProvider from "../components/SSO/SSOServiceProvider";
import SSOServiceMetadata from "../components/SSO/SSOServiceMetadata";
import SSOServiceAttribute from "../components/SSO/SSOServiceAttribute";

const tabs = [
  { name: "Service Provider Setup", icon: CogIcon },
  { name: "Service Provide Metadata", icon: ShareIcon },
  { name: "Attribute/Role Mapping", icon: TableIcon },
];

const SSODetails = () => {
  const [selectedTab, setSelectedTab] = useState("Service Provider Setup");

  // eslint-disable-next-line no-unused-vars
  const [uploadType, setUploadType] = useState("manual");
  return (
    <>
      <PageHeader>SSO</PageHeader>

      <div className="border-b border-gray-200 overflow-auto sm:overflow-hidden">
        <nav className="-mb-px flex space-x-8" aria-label="Tabs">
          {tabs.map((tab, i) => (
            <div
              key={tab.name}
              onClick={() => setSelectedTab(tab.name)}
              className={classNames(
                tab.name === selectedTab ? "border-highlightColor text-highlightColor" : "border-transparent text-gray-400 hover:text-gray-500 hover:border-gray-400",
                "group inline-flex items-center pb-3 px-1 border-b-2 font-medium text-sm cursor-pointer"
              )}
              aria-current={tab.name === selectedTab ? "page" : undefined}
            >
              <tab.icon className={classNames(tab.name === selectedTab ? "text-highlightColor" : "text-gray-400 group-hover:text-gray-400", "-ml-0.5 mr-2 h-5 w-5")} aria-hidden="true" />
              <span className="block whitespace-nowrap">{tab.name}</span>
            </div>
          ))}
        </nav>
      </div>

      <div>
        {tabs.find((tab) => tab.name === selectedTab).name === "Service Provider Setup" ? (
          <SSOServiceProvider />
        ) : tabs.find((tab) => tab.name === selectedTab).name === "Service Provide Metadata" ? (
          <SSOServiceMetadata />
        ) : (
          <SSOServiceAttribute />
        )}
      </div>
    </>
  );
};

export default SSODetails;
