import { hostUrl } from "src/config/host";
import { fetchData } from "src/async/fetch";
import {
  GET_DASHBOARD_DATA,
  GET_DASHBOARD_CRED_DATA,
  // REMOVE_DASHBOARD
} from "./type";

export const setDashboardData = (body) => (dispatch) => {
  dispatch({ type: GET_DASHBOARD_DATA, payload: body });
};

export const orderingDashboardData = (body) => async (dispatch) => {
  try {
    const res = await fetchData("POST", `${hostUrl}/dashboard/ordering`, body);
    const resData = await res.json();

    if (resData.status === 200) {
      dispatch({ type: GET_DASHBOARD_DATA, payload: resData.data });
      return { message: resData.message, data: resData.data };
    } else {
      throw new Error(resData.message);
    }
  } catch (err) {
    throw new Error(err.message);
  }
};

export const manageDashboardData = (body) => async (dispatch) => {
  try {
    const res = await fetchData("POST", `${hostUrl}/dashboard/manage`, body);
    const resData = await res.json();

    if (resData.status === 200) {
      dispatch({ type: GET_DASHBOARD_DATA, payload: resData.data });
      return { message: resData.message, data: resData.data };
    } else {
      throw new Error(resData.message);
    }
  } catch (err) {
    throw new Error(err.message);
  }
};

export const getDashboardData =
  (body, signal = null) =>
  async (dispatch) => {
    try {
      const res = await fetchData("POST", `${hostUrl}/dashboard/list`, body, signal);
      const data = await res.json();

      if (data.status === 200) {
        dispatch({ type: GET_DASHBOARD_DATA, payload: data.data });
        return data.data;
      } else {
        throw new Error(data.message);
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };

export const removeDashboard = (id) => async (dispatch) => {
  try {
    const res = await fetchData("POST", `${hostUrl}/dashboard/delete`, { id });
    const data = await res.json();

    if (data.status === 200) {
      // dispatch({ type: REMOVE_DASHBOARD, payload: id });
      return data.message;
    } else {
      throw new Error(data.message);
    }
  } catch (err) {
    throw new Error(err.message);
  }
};

export const getGroupPermissionData = async (groupIds) => {
  try {
    const res = await fetchData("POST", `${hostUrl}/dashboard/group/permissions`, { groupIds });
    const data = await res.json();

    if (data.status === 200) {
      return data;
    } else {
      throw new Error(data.message);
    }
  } catch (err) {
    throw new Error(err.message);
  }
};

export const getCredData =
  (body, signal = null) =>
  async (dispatch) => {
    try {
      const res = await fetchData("POST", `${hostUrl}/dashboard/credential/get`, body, signal);

      const resData = await res.json();

      if (resData.status === 200) {
        dispatch({ type: GET_DASHBOARD_CRED_DATA, payload: resData.data });
        return resData.data;
      } else {
        throw new Error(resData.message);
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };

export const manageCredData =
  (body, signal = null) =>
  async (dispatch) => {
    try {
      const res = await fetchData("POST", `${hostUrl}/dashboard/credential/manage`, body, signal);

      const resData = await res.json();

      if (resData.status === 200) {
        dispatch({ type: GET_DASHBOARD_CRED_DATA, payload: resData.data });
        return resData;
      } else {
        throw new Error(resData.message);
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };

export const deleteCredData =
  (body, signal = null) =>
  async (dispatch) => {
    try {
      await fetchData("DELETE", `${hostUrl}/dashboard/credential/${body}`, signal);
    } catch (err) {
      throw new Error(err.message);
    }
  };
