import { Switch } from "@headlessui/react";
import { classNames } from "src/helpers/classNames";

export default function Toggle({ value, setValue, disabled = false }) {
  const handleToggle = (e) => {
    setValue(e);
  };

  return (
    <div className={classNames(disabled ? "cursor-not-allowed" : "")}>
      <Switch.Group as="div" className="flex items-center">
        <Switch
          checked={value}
          disabled={disabled}
          onChange={handleToggle}
          className={classNames(
            disabled ? "cursor-not-allowed" : "cursor-pointer",
            disabled && value ? "bg-gray-300" : value ? "bg-highlightColor" : "bg-gray-200",
            "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none"
          )}
        >
          <span
            aria-hidden="true"
            className={classNames(
              disabled ? "cursor-not-allowed" : "pointer-events-none",
              value ? "translate-x-5" : "translate-x-0",
              "inline-block h-5 w-5 rounded-full bg-white shadow-md transform ring-0 transition ease-in-out duration-200"
            )}
          />
        </Switch>
      </Switch.Group>
    </div>
  );
}
