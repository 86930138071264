import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getDatasetAccess, pushDataPotal } from "src/actions/site";
import { domoUsersToPortalUsersAll, getGroupData, getUsers, getUsersList } from "src/actions/user";
import SelectMenu from "src/components/Shared/SelectMenu";

import { CloudUploadIcon, SwitchHorizontalIcon } from "@heroicons/react/outline";
import Button from "src/components/Shared/Button";
import Group from "./Group";
import Search from "./Search";
import { classNames } from "src/helpers/classNames";
import PreloaderIcon from "../PreloaderIcon";

const CardUserForm = ({ users, creds, portal, getUsers, getUsersList, type, perPage, setPerpage, offset, groups, ...props }) => {
  const [status, setStatus] = useState("");
  const [group, setGroup] = useState("");
  const [keyword, setKeyword] = useState("");
  const [isLoad, setLoad] = useState(false);
  const [isLoadRefreshPermissions, setIsLoadRefreshPermissions] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    const ac = new AbortController();

    const loadFilteredUser = async () => {
      props.setLoaded(false);

      try {
        const userCount = await getUsers(
          {
            active_status: status,
            group: group,
            keyword,
            type,
            limit: +perPage,
            offset: offset * +perPage,
          },
          ac.signal
        );

        props.setCount(userCount);

        const availablePages = Math.ceil(userCount / +perPage);
        props.setPages(availablePages);
        props.setLoaded(true);
      } catch (err) {
        props.setLoaded(true);
        console.dir(err.message);
      }
    };

    loadFilteredUser();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, perPage, users.length]);

  useEffect(() => {
    const ac = new AbortController();

    const loadFilteredUser = async () => {
      props.setLoaded(false);

      try {
        const userCount = await getUsers(
          {
            active_status: status,
            group: group,
            keyword,
            type,
            limit: +perPage,
            offset: 0,
          },
          ac.signal
        );
        props.setCount(userCount);

        const availablePages = Math.ceil(userCount / +perPage);
        props.setPages(availablePages);
        props.setOffset(0);
        props.setLoaded(true);
      } catch (err) {
        props.setLoaded(true);
        console.dir(err.message);
      }
    };

    loadFilteredUser();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword, status, group]);

  useEffect(() => {
    const ac = new AbortController();

    const loadGroups = async () => {
      try {
        await getGroupData({}, ac.signal);
      } catch (err) {
        console.dir(err.message);
      }
    };

    loadGroups();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const convertDomoUsersToPortalUsers = async (e) => {
    e.preventDefault();
    setIsLoadRefreshPermissions(true);

    if (type === "portal") {
      setIsLoadRefreshPermissions(false);
      return;
    }

    props.setLoaded(false);

    try {
      await props.domoUsersToPortalUsersAll();
      const userCount = await getUsers({
        active_status: null,
        group: null,
        keyword: "",
        type: "",
        limit: 10,
        offset: 0,
      });

      props.setCount(userCount);

      setPerpage("10");
      props.setOffset(0);
      props.setLoaded(true);
      setKeyword("");
      setStatus("");

      const availablePages = Math.ceil(userCount / 10);
      props.setPages(availablePages);
      setIsLoadRefreshPermissions(false);
    } catch (err) {
      props.setLoaded(true);
      setIsLoadRefreshPermissions(false);
      console.dir(err.message);
    }
  };
  const datasetId = props.site.portal_dataset_id;

  const [dataUrl] = useState(`https://api.domo.com/v1/json/${datasetId}/data`);
  const [authUrl] = useState("https://api.domo.com/oauth/token?grant_type=client_credentials&scope=data%20user");

  const loadAccessToken = async () => {
    const base64Cred = btoa(`${creds.clientId}:${creds.clientSecret}`);
    const data = await getDatasetAccess(
      null,
      null,
      {
        Authorization: `Basic ${base64Cred}`,
      },
      authUrl
    );

    return data.access_token;
  };

  const domoUpdate = async () => {
    try {
      setLoad(true);
      const userList = await getUsersList({
        active_status: status,
        keyword,
        type,
        limit: +perPage,
        offset: offset * +perPage,
      });
      const accessToken = await loadAccessToken();
      let ListUser = [];
      userList.forEach((item) => {
        item.permissions.forEach((permission) => {
          // ListUser.push(permission);
          ListUser.push({
            Name: item.name,
            Email: item.email,
            Notifications: "No",
            "Dashboard Name": permission.dashboard_name,
            Operator: permission.operator_name ? permission.operator_name : "",
            "Column Name": permission.column_name,
            "Column Value": permission.column_value,
          });
        });
      });

      const pushPortal = await pushDataPotal(
        ListUser,
        null,
        {
          Authorization: `Bearer ${accessToken}`,
        },
        dataUrl
      );
      if (!pushPortal) {
        setLoad(false);
        toast.error("Something went wrong");
      } else {
        setLoad(false);
        toast.success("Successfully updated data in domo");
      }
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <div className="flex justify-between items-center">
      <div className="w-full justify-between flex">
        <div className="flex gap-x-2 w-4/5">
          <Search keyword={keyword} setKeyword={setKeyword} />
          <div className="hidden md:flex gap-x-1">
            <SelectMenu
              options={[
                { key: "10 Per Page", value: 10 },
                { key: "20 Per Page", value: 25 },
                { key: "50 Per Page", value: 50 },
                { key: "75 Per Page", value: 75 },
                { key: "100 Per Page", value: 100 },
                { key: "All", value: 1000000000 },
              ]}
              setOption={(option) => {
                setPerpage(option.value);
                props.setOffset(0);
              }}
            />

            <SelectMenu
              options={[
                { key: "All Status", value: "" },
                { key: "Active", value: "true" },
                { key: "Inactive", value: "false" },
              ]}
              setOption={(option) => {
                if (option.value) {
                  setStatus(option.value === "true");
                } else {
                  setStatus(option.value);
                }
              }}
            />

            <Group group={group} setGroup={setGroup} />
          </div>
        </div>
        <div className="relative flex w-[140px] gap-x-1 items-center justify-end">
          <div className={classNames("hidden md:flex absolute -top-14 right-0 justify-end", props.site.api_state !== "domo" ? "w-[160px]" : "w-[360px]")}>
            {props.site.api_state !== "domo" ? (
              <Button styleType="gray" onClick={() => navigate("/bulk-user-upload")}>
                <CloudUploadIcon className="h-4" />
                Upload {type === "admin" ? "Admins" : "Users"}
              </Button>
            ) : type === "portal" ? (
              <Button styleType="gray" onClick={() => navigate("/bulk-user-upload")}>
                <CloudUploadIcon className="h-4" />
                Upload {type === "admin" ? "Admins" : "Users"} To Domo
              </Button>
            ) : (
              <div className="flex justify-end gap-x-3 w-full">
                <Button styleType="gray" onClick={convertDomoUsersToPortalUsers}>
                  {isLoadRefreshPermissions ? <PreloaderIcon height="h-4" /> : <SwitchHorizontalIcon className="h-4" />}
                  Refresh Permissions
                </Button>
                {props.site.portal_dataset_id && (
                  <Button styleType="gray" onClick={domoUpdate}>
                    {isLoad ? <PreloaderIcon height="h-4" /> : <CloudUploadIcon className="h-4" />}
                    Update Domo
                  </Button>
                )}
              </div>
            )}
          </div>
          {props.site.api_state !== "domo" && (
            <Button
              styleType="secondary"
              onClick={() => navigate(type === "admin" ? `/manage-admin${type === "admin" ? "?isAdmin=true" : ""}` : `/manage-user${type === "admin" ? "?isAdmin=true" : ""}`)}
            >
              Add {type === "admin" ? "Admin" : "User"}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    creds: state.creds,
    site: state.site,
    groups: Object.values(state.groups),
  };
};

export default connect(mapStateToProps, { getUsers, getUsersList, domoUsersToPortalUsersAll, getGroupData })(CardUserForm);
