import { useEffect, useState } from "react";
import { connect } from "react-redux";
// import { useNavigate } from "react-router-dom";
import { getGroupDataCount } from "src/actions/user";
import Button from "src/components/Shared/Button";
import SelectMenu from "src/components/Shared/SelectMenu";
import Search from "src/components/Users/Search";

const CardGroupForm = ({ groups, getGroupDataCount, perPage, setPerpage, offset, ...props }) => {
  const [keyword, setKeyword] = useState("");
  // const navigate = useNavigate();
  useEffect(() => {
    const ac = new AbortController();

    const loadFilteredGroup = async () => {
      props.setLoaded(false);

      try {
        const groupCount = await getGroupDataCount(
          {
            keyword,
            limit: +perPage,
            offset: offset * +perPage,
          },
          ac.signal
        );

        props.setCount(groupCount);

        const availablePages = Math.ceil(groupCount / +perPage);
        props.setPages(availablePages);
        props.setLoaded(true);
      } catch (err) {
        props.setLoaded(true);
        console.dir(err.message);
      }
    };

    loadFilteredGroup();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, perPage, groups.length]);

  useEffect(() => {
    const ac = new AbortController();

    const loadFilteredGroup = async () => {
      props.setLoaded(false);

      try {
        const groupCount = await getGroupDataCount(
          {
            keyword,
            limit: +perPage,
            offset: 0,
          },
          ac.signal
        );

        props.setCount(groupCount);

        const availablePages = Math.ceil(groupCount / +perPage);
        props.setPages(availablePages);
        props.setOffset(0);
        props.setLoaded(true);
      } catch (err) {
        props.setLoaded(true);
        console.dir(err.message);
      }
    };

    loadFilteredGroup();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword]);

  return (
    <div className="flex justify-between items-center">
      <div className="w-full justify-between flex">
        <div className="flex gap-x-2 w-4/5">
          <Search keyword={keyword} setKeyword={setKeyword} />
          <div className="hidden md:flex gap-x-1">
            <SelectMenu
              options={[
                { key: "10 Per Page", value: 10 },
                { key: "20 Per Page", value: 25 },
                { key: "50 Per Page", value: 50 },
                { key: "75 Per Page", value: 75 },
                { key: "100 Per Page", value: 100 },
                { key: "All", value: 1000000000 },
              ]}
              setOption={(option) => {
                setPerpage(option.value);
                props.setOffset(0);
              }}
            />
          </div>
        </div>
        <div className=" relative flex w-[140px] gap-x-1 items-center justify-end">
          <Button
            styleType="secondary"
            onClick={() => {
              props.setGroupModalOpen(true);
              props.setAddGroupStatus(true);
              props.setEditGroupStatus(false);
              props.setEditId(null);
            }}
          >
            Add Group
          </Button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
  };
};

export default connect(mapStateToProps, { getGroupDataCount })(CardGroupForm);
